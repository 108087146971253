import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

const validateForm = data => {
    const errors = {};  
    console.log(data);
    if (!data.email) {
        errors.email = 'Please Enter Email';
    }   
    if (!data.password) {
        errors.password = 'Please Enter Password';
    }
    console.log(errors);
    return errors;
};

export default function Login() {
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        var head = document.head;
        var link = document.createElement("link");
    
        link.type = "text/css";
        link.rel = "stylesheet";
        link.href = process.env.PUBLIC_URL+"/assets/css/login_main.css";    
        head.appendChild(link);
        setIsLoading(false);
        return () => { head.removeChild(link); }
        
      }, []);
    const navigate = useNavigate();    
    const [isError, setIsError] = useState(false);
    const formik = useFormik({
        initialValues:{
            email: '',
            password: '',
        },
        validate: validateForm,
        onSubmit: values => {
            login(values);            
        }
    });

    const login = async(data) => {
        data = JSON.stringify(data); 
        console.log(data);
        await axios.post('/admin/login',data)
        .then(function (response) {
            let result = response.data;
            if(result.success){
                setIsError(false);
                localStorage.setItem('authUser', JSON.stringify(result.data));
                localStorage.setItem('token', result.data.token);
                localStorage.setItem('role', result.data.role);
                navigate('/providers');
            }
            else {
                console.log(result);
                setIsError(true);
            }
        })
    }

    return (
    <>
    {
        isLoading === false ?
        <>
        <div className="top-header">
            <div className="container">
                <div className="row">
                    <div className="col-6 mt-2">
                        <div className="home-logo">
                            <img src="assets/img/brightlife-logo.png" alt="BrightLife" style={{width: '38%'}}/>
                            {/* <span>Bright Life</span> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <section className="login-block">
            <div className="container">
                <div className="signup-container">
                    <div className="row">
                        <div className="col-md-8 banner-sec">
                            <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                                <ol className="carousel-indicators">
                                    <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                                    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>                        
                                    <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>                        
                                    <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>                     
                                </ol>
                                <div className="carousel-inner" role="listbox">
                                    <div className="carousel-item active">
                                        <img className="d-block img-fluid" src="assets/img/slide-1.jpg" alt=""/>
                                        {/* <div className="carousel-caption d-none d-md-block">
                                            <div className="banner-text">
                                                <h2>We Are ExpertTal</h2>
                                                <p>Hire Developers According to Your Requirements</p>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="carousel-item">
                                        <img className="d-block img-fluid" src="assets/img/slide-2.jpg" alt=""/>
                                        {/* <div className="carousel-caption d-none d-md-block">
                                            <div className="banner-text">
                                                <h2>We Are ExpertTal</h2>
                                                <p>Hire from the fastest growing platform for IT Freelancers / Resources from India.</p>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="carousel-item">
                                        <img className="d-block img-fluid" src="assets/img/slide-3.jpg" alt=""/>
                                        {/* <div className="carousel-caption d-none d-md-block">
                                            <div className="banner-text">
                                                <h2>We Are ExpertTal</h2>
                                                <p>Hire the Best remote IT Professional who can work for Startups / Enterprises / Fortune 500 companies globally.</p>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="carousel-item">
                                        <img className="d-block img-fluid" src="assets/img/slide-4.jpg" alt=""/>
                                        {/* <div className="carousel-caption d-none d-md-block">
                                            <div className="banner-text">
                                                <h2>We Are ExpertTal</h2>
                                                <p>Professional who can work for Startups/ Enterprises / Fortune 500 companies globally.</p>
                                            </div>
                                        </div> */}
                                    </div>                          
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 login-sec">
                            <div className="logo">
                            <Link to="/">
                                    <img src="assets/img/brightlife-logo.png" alt="BrightLife"/>
                                </Link>
                            </div>
                            <Link to="/" className="goback">                       
                                <img className="goback-home" src="assets/img/goBack.png" alt="Home" data-toggle="tooltip" data-placement="right" title="Go Back to Home"/>
                            </Link>  
                            <form onSubmit={formik.handleSubmit}>
                                <div className="auth-item form">
                                    <h4 className="heading ft-primary fw-600 text-center ft-uppercase mt-3">Log in and get to work</h4>
                                    <p className="ft-xss">To keep connected with us please login with your personal details</p>   
                                    <div className="error text-danger" style={{ display: isError === true ? 'block' : 'none' }}>Incorrect Email or password!</div>
                                    <div className="form-group adon-group mt-4">
                                        <span className="icon icon-md ft-dark-500"><i className="fa fa-user"></i></span>
                                        <input type="text" name="email" className="form-control" placeholder="Email" defaultValue={formik.values.email} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                                    </div>
                                    {formik.touched.email && formik.errors.email ? <div className="text-danger">{formik.errors.email}</div> : null}

                                    <div className="form-group adon-group">
                                        <span className="icon icon-md ft-dark-500"><i className="fa fa-lock"></i></span>
                                        <input type="password" name="password" className="form-control" placeholder="Password" defaultValue={formik.values.password} onChange={formik.handleChange} onBlur={formik.handleBlur}/>  
                                    </div>
                                    {formik.touched.password && formik.errors.password ? <div className="text-danger">{formik.errors.password}</div> : null}  
                                    <div className="auth-ctrl">
                                        <Link to="/forgot_password" className="ft-xs">Forgot Password ?</Link>
                                    </div>
                                    <div className="form-group">
                                        <button type='submit' className="r-btn r-btn-primary btn-block mt-2 ft-uppercase">
                                            <i className="fas fa-sign-in-alt"></i> Login
                                        </button>
                                    </div>
                                </div>
                            </form> 
                        </div>              
                    </div>                
                </div>
            </div>
        </section>
        <footer className="dark-footer skin-dark-footer">
            <div className="container">
                <div className="row">
                    <div className="col-lg-7 col-md-6">
                        <div className="footer-widget">
                        <img src="assets/expertal_logo-invert.png" className="img-fluid f-logo" width="120" alt=""/>
                        <p className="mb-3">Would you like to speak to one of our Project Managers over the phone? Just submit your details and we’ll be in touch shortly. You can also email us if you would prefer</p>
                        <div className="row">
                            <div className="col-md-6">
                                <p>
                                    <i className="fas fa-map-marker-alt"></i> <span className="fw-600">Head Office:</span>  
                                    <br/> 707, Siddhartha Building, 96,
                                    <br/>Nehru Place, New Delhi - 110019, India<br/>                            
                                    <i className="fas fa-phone"></i> <span> +91 88600 77301 | +91 8860077309</span>
                                </p>
                                <hr/>                                            
                            </div>
                            <div className="col-md-6">
                                <p>
                                    <i className="fas fa-map-marker-alt"></i> <span className="fw-600">Operations Office:</span>  
                                    <br/> Apex Square, Plot No - 11,
                                    <br/> Pocket 3 B, Sector 17, Dwarka,<br/>                            
                                    <span> New Delhi -110075, India</span>
                                    </p>
                                <hr/> 
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <p>
                                    <i className="fas fa-map-marker-alt"></i> <span className="fw-600">Bengaluru Office:</span>  
                                    <br/> 3rd Floor, Novel Tech Park,
                                    <br/> Hosur Road, Garvebhavi Palya, Bangalore-68, India<br/>                            
                                    <i className="fas fa-phone"></i> <span> +91 88600 77301 | +91 8860077309</span>
                                </p>
                                <hr/>                        
                            </div>
                            <div className="col-md-6">
                                <p>
                                    <i className="fas fa-map-marker-alt"></i> <span className="fw-600">US Office:</span>  
                                    <br/> 100 Franklin Sq. Drive, Ste 207 
                                    <br/> Somerset, NJ - 08873, USA<br/>                            
                                    <i className="fas fa-phone"></i> <span> +1 609 759 1914</span>
                                    </p>
                                <hr/>                        
                            </div>                  
                        </div>
                        </div>
                    </div>                      
                    <div className="col-lg-5 col-md-6">
                        <div className="footer-widget">
                        <h4 className="widget-title">Write to Us</h4>                    
                        <form action="#" className="contact-form form-validate" noValidate="novalidate">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label className="required-field ft-light-300" htmlFor="firstName">First Name</label>
                                        <input type="text" className="form-control" id="firstName" name="firstName" placeholder="First Name"/>
                                    </div>
                                </div>
                    
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label className="required-field ft-light-300" htmlFor="lastName">Last Name</label>
                                        <input type="text" className="form-control" id="lastName" name="lastName" placeholder="Last Name"/>
                                    </div>
                                </div>
                    
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label className="required-field ft-light-300" htmlFor="email">Email</label>
                                        <input type="text" className="form-control" id="email" name="email" placeholder="type your email..."/>
                                    </div>
                                </div>
                    
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label className="required-field ft-light-300" >Phone Number</label>
                                        <input type="number" className="form-control" id="phone" name="phone" placeholder="type your mobile number..."/>
                                    </div>
                                </div>
                    
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label className="required-field ft-light-300" htmlFor="message">How can we help?</label>
                                        <textarea className="form-control" id="message" name="message" rows="4" placeholder="Hi there, I would like to....."></textarea>
                                    </div>
                                </div>
                    
                                <div className="col-sm-12">
                                    <a href="" type="submit" name="submit" className="r-btn r-btn-primary btn-block"><i className="fa fa-paper-plane"></i><span> Submit</span></a>
                                </div>
                    
                            </div>
                        </form>
                        <div className="contact mt-4"> <span><i className="fa fa-envelope"></i></span>
                        <div className="information"> 
                            <strong>Email Address:</strong>
                            <p><a href="mailto:support@brightlife.com">support@brightlife.com</a></p>
                        </div>
                        </div>
                        </div>            
                    </div>                
                </div>
            </div>
            <div className="footer-bottom">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12 col-md-12 text-center">
                        <p className="mb-0">© 2022  Copyright BrightLife. All Rights Reserved</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        </>
        : ''
    }
    
    </>
  );
}