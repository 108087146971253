import React, { useEffect, useState }  from 'react';
import PropTypes from "prop-types";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';
import { useFormik } from 'formik';
import * as yup from 'yup';

const validationSchema = yup.object({
    userId: yup.string('Select Client').required('Client is required'),
    providerId: yup.string('Select Provider').required('Provider is required'),
    sessionId: yup.string('Select Session plan').required('Session plan is required'),
    bookingDate: yup.string('Select Booking date').required('Booking date is required'),
});
export const AddSession = ({type, show, setShow, handleClose, getList, ...rest}) => {      
    const [providers,setProviders] = useState([]);
    const [clients,setClients] = useState([]);
    const [sessionsPlans, setSessionsPlans] = useState([]);

    const initialValues = {
        userId: '',
        providerId: '',
        sessionId: '',
        bookingDate: '',
        type: 'Session',
        name: '',
        mobile: '',
        email: '',
        gender: ''
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: values => {
            console.log("sssss=",values);
            handleSave(values);
        }
    });

    useEffect(() => {
        getProviders();
        getClients();        
    },[]);

    const getProviders = async() => {
        await axios.get('/api/users?role='+'Provider')
        .then(function (response) {
            let result = response.data;
            if(result.success){
                setProviders(result.data)
            }
        })
    }
    const getClients = async () => {
        await axios.get('/api/users?role='+'Client')
        .then(function (response) {
            let result = response.data;
            if(result.success){
                setClients(result.data)
            }
        })
    }
    const getSessionsPlans = async(providerId) => {
        await axios.get('/admin/providers/sessionsPlans?userId='+providerId)
        .then(function (response) {
            let result = response.data;
            if(result.success){
                setSessionsPlans(result.data)
            }
        })
    }
    const handleProviderChange = (e) => {
        formik.setFieldValue('providerId', e.target.value)
        getSessionsPlans(e.target.value)
    }
    const handleClientChange = async (e) => { 
        formik.setFieldValue('userId', e.target.value)
        
        let selectedClient = await getSelectedClientDetails(e.target.value);            
        console.log(selectedClient[0].name);
    }

    const getSelectedClientDetails = async (userId) => {
        return new Promise(function(resolve, reject){
            let userDetails = clients.filter(function(obj) {
                if (obj.id == userId)
                    return obj;
            })
            resolve(userDetails)
        });
    }
    
    const handleSave = (values) =>{
        console.log(values)
        axios.post(`api/bookings/create`, values).then(res => {
            if(res.data.success){
                setShow(false);
                Swal.fire(res.data.message, '', 'success').then(result => {
                    getList();                    
                    formik.setFieldValue("userId",'');
                    formik.setFieldValue("providerId",'');
                    formik.setFieldValue("sessionId",'');
                    formik.setFieldValue("bookingDate",'');
                })                
            } 
            else{
                Swal.fire(res.data.message, '', 'error')
            }
        }).catch((error) => {
            // console.log(error.response.data)
            Swal.fire(error.response.data.message, '', 'error')
        });      
    }
    return (
        <Modal show={show} onHide={handleClose} dialogClassName="modal-md" style={{zIndex: 9999}}>
            <Modal.Header>
                <h5 className="modal-title">{type} Session Booking</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </Modal.Header>
            
            <form onSubmit={formik.handleSubmit}>
            <Modal.Body>
                <div className="form-group">
                    <label htmlFor="">Select Client</label>
                    {/* <select className="form-control" value={form.userId} onChange={(e) => setForm({...form, userId: e.target.value})}> */}
                    <select className="form-control" name="userId" value={formik.values.userId} onChange={(e) => handleClientChange(e)}>
                        <option value="">--Please Select--</option>
                        {
                            clients.map( (client) => {
                               return <option key={"c_"+client.id} value={client.id}>{client.name +" - "+ client.email}</option>
                            } )
                        }
                    </select>
                </div>
                {formik.touched.userId && formik.errors.userId ? <div className="text-danger">{formik.errors.userId}</div> : null}
                <div className="form-group">
                    <label htmlFor="">Select Provider</label>
                    <select className="form-control" name="providerId" value={formik.values.providerId} onChange={(e) => handleProviderChange(e)}>
                        <option value="">--Please Select--</option>
                        {
                            providers.map( (provider) => {
                               return <option  key={"p_"+provider.id} value={provider.id}>{provider.name +" - "+ provider.email}</option>
                            } )
                        }
                    </select>
                </div>
                {formik.touched.providerId && formik.errors.providerId ? <div className="text-danger">{formik.errors.providerId}</div> : null}
                <div className="form-group">
                    <label htmlFor="">Select Session Plan</label>
                    <select className="form-control" name="sessionId" value={formik.values.sessionId} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                        <option value="">--Please Select--</option>
                        {
                            sessionsPlans.map( (sessionsPlan) => {
                               return <option  key={sessionsPlan.id} value={sessionsPlan.id}>{sessionsPlan.name}</option>
                            } )
                        }
                    </select>
                </div>
                {formik.touched.sessionId && formik.errors.sessionId ? <div className="text-danger">{formik.errors.sessionId}</div> : null}

                <div className="form-group">
                    <label htmlFor="">Booking Date</label>
                    <input className="form-control" type="date" name="bookingDate" value={formik.values.bookingDate} onChange={formik.handleChange} onBlur={formik.handleBlur} id="html5-date-input"/>
                </div>
                {formik.touched.bookingDate && formik.errors.bookingDate ? <div className="text-danger">{formik.errors.bookingDate}</div> : null}
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="r-btn r-btn-base" onClick={handleClose}>Close</button>
                <button type="submit" className="r-btn r-btn-primary">{type == 'Add' ? 'Add' : 'Update'}</button>
            </Modal.Footer>
            </form>
        </Modal>
    );
}

export default AddSession;
AddSession.propTypes = {
    type: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired    
};