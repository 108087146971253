import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Table from '../Common/Table';
import { Layout } from '../Layout/Layout';
import axios from 'axios';
import Swal from 'sweetalert2'
import Loader from '../Loader/Loader';

export default function List() {
    const [clients,setClients] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getList();
      }, []);
    const getList = async () => {
        setLoading(true)
        await axios.get('/api/users?role='+'Client')
        .then(function (response) {
        setLoading(false);
        let result = response.data;
            if(result.success){
                setClients(result.data)
            }
        })
    }
    
    const handleDelete = (userId) => {
        Swal.fire({
            title: 'Are you sure you want to delete?',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`/admin/delete?id=${userId}`).then((res) =>{
                    if(res.data.success){                             
                        Swal.fire('Deleted!', '', 'success')           
                        setClients(clients.filter((v, i) => v.id !== userId));
                    } 
                }).catch((error) => {
                    console.log(error);                     
                    Swal.fire(error.message, '', 'info')           
                });
            } else if (result.isDenied) {
              Swal.fire('Cancelled!', '', 'info')
            }
          })        
    }
    function updateUserStatus(userId, data){
        return axios.patch(`/admin/update/${userId}`, data);
    }
    const columns = [
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
        },
        {
            name: 'Phone',
            selector: row => row.phone,
        },
        {
            name: 'Date of Birth',
            selector: row => row.user_detail && row.user_detail.dateOfBirth ? row.user_detail.dateOfBirth : "-",
        },
        {
            name: 'Wallet',
            cell: row => (
                    <input className="" size="5" name="amount" type="text" onBlur={handleWalletChange} id={row.id}  defaultValue={row.user_wallet !== null ? row.user_wallet.balance : 0.00}/>
            ),
        },
        {
            name: 'Status',
            cell: row => (
                <div>
                    <input className="tgl tgl-light toggle_user_status" name="status"  id={"chk_"+row.id} type="checkbox" onChange={handleStatusChange}  defaultChecked={row.status == 'Active'?true:false}/>
                    <label title="Active" className="tgl-btn mt-1" htmlFor={"chk_"+row.id} ></label>
                </div>
            ),
        },
        {
            name: 'Action',
            button: true,
            cell: row => (
                <div>
                    {/* <Link to={`/clients/edit/${row.id}`} className="icon icon-sm icon-secondary ml-1">
                        <i className="fa fa-pencil-alt" title="Edit User"></i>
                    </Link> */}
                    <a href="#" className="icon icon-sm icon-primary ml-1"  onClick={e => handleDelete(row.id)}>
                        <i className="fa fa-times" data-toggle="tooltip" data-placement="right" title="" data-original-title="Delete User"></i>
                    </a>
                </div>
            ),
        }
    ];
    const handleStatusChange = (e)=>{
        setLoading(true);
        let inputId = e.target.id;
        let inputIdArr = inputId.split("_")
        const userId = inputIdArr[1];

        if(e.target.checked === true) {
            updateUserStatus(userId, {status:'Active'}).then((res) =>{
                setTimeout(() => {
                    setLoading(false);
                  }, 1000);
            }).catch((error) => {
                console.log(error);                
            });
        }
        if(e.target.checked === false) {
            updateUserStatus(userId, {status:'Inactive'}).then((res) =>{
                setTimeout(() => {
                    setLoading(false);
                  }, 1000);   
            }).catch((error) => {
                console.log(error);                
            });
        }
    }
    
    const handleWalletChange = (e) => {
        let inputId = e.target.id;
        let inputValue = e.target.value;
        let data = {};
        data.userId = inputId;
        data.balance = inputValue;
        
        Swal.fire({
            title: 'Are you sure you want to update this value?',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, update it!'
        }).then((result) => {
            if (result.isConfirmed) {
                setLoading(true);
                axios.post(`admin/client/UpdateWallet`, data).then(res => {
                    setLoading(false);
                    if(res.data.success){   
                        Swal.fire(res.data.message, '', 'success')
                    } 
                    else{
                        Swal.fire(res.data.message, '', 'error')
                    }   
                }).catch((error) => {
                    Swal.fire(error.response.data.message, '', 'error')
                });
            } else if (result.isDenied) {
                Swal.fire('Cancelled!', '', 'info')
              }
        })


    }
    return (
        <>
        {loading ? <Loader/> : ''};
            <Layout>  
                <div className="row">
                    <div className="col-6">
                        <h1 className="display-4 ft-dark exprtl-page-title">Clients List</h1>
                    </div>
                    <div className="col-6 text-right">
                    </div>
                </div>
                <div className="app-white-container">
                    {/* <div className="alert alert-success alert-white rounded" style={{border:'0px'}}>
                        <button type="button" className="close" data-dismiss="alert" aria-hidden="true">×</button>
                        <div className="icon"><i className="fa fa-check"></i></div>
                        User has been Added Successfully.
                    </div> */}
                    <div className="row">
                        <Table columns={columns} data={clients}/>
                    </div>
                </div>                
            </Layout>  
        </>
    )

}