import React from "react";
import { Link, useLocation } from "react-router-dom";

export default function Sidebar() {    
  const location = useLocation();
  return (
    <>
       <div className="app-sidebar sidebar-shadow ">
        <div className="app-header__logo">
            <div className="logo-src"></div>
                <div className="header__pane ml-auto"><div>
                    <button type="button" className="hamburger close-sidebar-btn hamburger--elastic is-active" data-class="closed-sidebar">
                        <span className="hamburger-box">
                            <span className="hamburger-inner"></span>
                        </span>
                    </button>
                </div>
            </div>
        </div>
        <div className="app-header__mobile-menu">
            <div>
                <button type="button" className="hamburger hamburger--elastic mobile-toggle-nav">
                    <span className="hamburger-box">
                        <span className="hamburger-inner"></span>
                    </span>
                </button>
            </div>
        </div>    
        <div className="app-header__menu">
            <span>
                <button type="button" className="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav">
                    <span className="btn-icon-wrapper">
                        <i className="fa fa-ellipsis-v fa-w-6"></i>
                    </span>
                </button>
            </span>
        </div>
        <div className="scrollbar-sidebar">
            <div className="app-sidebar__inner mt-2">
                <ul className="vertical-nav-menu metismenu">
                    {/* <li className="app-sidebar__heading"></li>                    */}
                    <li>
                        <Link to="/providers" className={location.pathname === "/providers"? "mm-active" : ""}>
                            <i className="metismenu-icon pe-7s-users"></i>Providers
                        </Link>
                    </li>
                    <li>
                        <Link to="/clients" className={location.pathname === "/clients"? "mm-active" : ""}>
                            <i className="metismenu-icon pe-7s-users"></i>Clients
                        </Link>
                    </li>
                    <li>
                        <Link to="/chats" className={location.pathname === "/chats"? "mm-active" : ""}>
                            <i className="metismenu-icon pe-7s-comment"></i>Chats
                        </Link>
                    </li>
                    <li>
                        <Link to="/bookings" className={location.pathname === "/bookings"? "mm-active" : ""}>
                            <i className="metismenu-icon pe-7s-study"></i>Bookings
                        </Link>
                    </li>
                    <li>
                        <Link to="/categories" className={location.pathname === "/categories"? "mm-active" : ""}>
                            <i className="metismenu-icon pe-7s-menu" ></i>Categories
                        </Link>
                    </li>
                    <li>
                        <Link to="/seeks" className={location.pathname === "/seeks"? "mm-active" : ""}>
                            <i className="metismenu-icon pe-7s-search" ></i>Seeks
                        </Link>
                    </li>
                    <li>
                        <Link to="/discounts" className={location.pathname === "/discounts"? "mm-active" : ""}>
                            <i className="metismenu-icon pe-7s-ticket"></i>Discounts
                        </Link>
                    </li>
                    {/* <li>
                        <Link to="/discount_coupons" className={location.pathname === "/discount_coupons"? "mm-active" : ""}>
                            <i className="metismenu-icon pe-7s-ticket"></i>Discount Coupons
                        </Link>
                    </li> */}
                    <li>
                        <Link to="/offers" className={location.pathname === "/offers"? "mm-active" : ""}>
                            <i className="metismenu-icon pe-7s-settings"></i>Settings
                        </Link>
                    </li>
                    
                </ul>
            </div>
        </div>
      </div>
    </>
  );
}
