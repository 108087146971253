import React, { useEffect, useState }  from 'react';
import PropTypes from "prop-types";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2'

export const AddSessionsPlans = ({type, userId, show, setShow, handleClose, getList, editId, setEditId, ...rest}) => {    
    const [form, setForm] = useState({
        id: editId,
        userId: userId,
        name: '',
        noOfSessions: '',
        price: '',
        description: '',
        aboutSession: ''
    });

    useEffect(() => {
        if(editId > 0){
            axios.get(`/admin/providers/sessionsPlan?id=${editId}`).then(res => {
                console.log(res);
                if(res.data.success){
                    setForm({...form, id: editId, name: res.data.data.name, noOfSessions: res.data.data.noOfSessions, price: res.data.data.price, description: res.data.data.description, aboutSession: res.data.data.aboutSession})
                }
            })
        }
        else{
            setForm({...form, id: editId, name: '', noOfSessions: '', price: '', description: '', aboutSession: ''})
        }
        
    },[editId]);
    
    const handleSave = () =>{
        if(editId > 0){
            axios.put(`/admin/providers/sessionsPlans/edit/${editId}`, form).then(res => {
                if(res.data.success){
                    setShow(false);                    
                    Swal.fire(res.data.message, '', 'success').then(result => {
                        getList();                        
                    })
                } 
                else{
                    Swal.fire(res.data.message, '', 'error')
                }
            }).catch((error) => {
                // console.log(error.response.data)
                Swal.fire(error.response.data.message, '', 'error')
            });
        }
        else{
            axios.post(`admin/providers/sessionsPlans/add`, form).then(res => {
                if(res.data.success){
                    setShow(false);
                    Swal.fire(res.data.message, '', 'success').then(result => {
                        getList();
                        setForm({...form, name: '', noOfSessions: '', price: '', description: '', aboutSession: ''})

                    })                
                } 
                else{
                    Swal.fire(res.data.message, '', 'error')
                }
            }).catch((error) => {
                // console.log(error.response.data)
                Swal.fire(error.response.data.message, '', 'error')
            });
        }        
    }
    return (
        <Modal show={show} onHide={handleClose} dialogClassName="modal-md" style={{zIndex: 9999}}>
            <Modal.Header>
                <h5 className="modal-title">{type} Sessions</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </Modal.Header>
            <Modal.Body>
                <div className="form-group">
                    <label htmlFor="">Name</label>
                    <input name="name" type="text" className="form-control" value={form.name} onChange={(e) => setForm({...form, name: e.target.value})}/>
                </div>
                <div className="form-group">
                    <label htmlFor="">No Of Sessions</label>
                    <input name="noOfSessions" type="number" className="form-control" value={form.noOfSessions} onChange={(e) => setForm({...form, noOfSessions: e.target.value})}/>
                </div>
                <div className="form-group">
                    <label htmlFor="">Price</label>
                    <input name="price" type="number" className="form-control" value={form.price} onChange={(e) => setForm({...form, price: e.target.value})}/>
                </div>
                <div className="form-group">
                    <label htmlFor="">Short Description</label>
                    <input name="description" type="text" className="form-control" value={form.description} onChange={(e) => setForm({...form, description: e.target.value})}/>
                </div>
                <div className="form-group">
                    <label htmlFor="">About Session</label>
                    {/* <input name="aboutSession" type="text" className="form-control" value={form.aboutSession} onChange={(e) => setForm({...form, aboutSession: e.target.value})}/> */}
                    <textarea name="aboutSession" type="text" className="form-control" value={form.aboutSession} onChange={(e) => setForm({...form, aboutSession: e.target.value})}>{form.aboutSession}</textarea>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="r-btn r-btn-base" onClick={handleClose}>Close</button>
                <button type="button" onClick={handleSave} className="r-btn r-btn-primary">{type == 'Add' ? 'Add' : 'Update'}</button>
            </Modal.Footer>
        </Modal>
    );
}

export default AddSessionsPlans;
AddSessionsPlans.propTypes = {
    type: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired    
};