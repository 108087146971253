import React from 'react';
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
import Footer from '../Footer/Footer';

export const Layout = (props) => {
    const { children } = props;
    return (
        <>
        <div className="app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header closed-sidebar">
            <Header />
            <div className="app-main">
                <Sidebar />
                <div className="app-main__outer">
                    <div className="app-main__inner">
                        { children }
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
        </>
    )
}