import React, { useEffect, useState }  from 'react';
import { Layout } from '../Layout/Layout';
import Swal from 'sweetalert2';
import axios from "axios";
import { useFormik } from 'formik';
import * as yup from 'yup';

const validationSchema = yup.object({
    amount: yup.string('Enter Amount').required('Amount is required!')
})
export default function Add () {
    const [newUserOffer, setNewUserOffer] = useState({id:"",amount:""});
    const [loading, setLoading] = useState(false);
    const [isError, setIsError] = useState(false);  

    const initialValues = {id:"",amount:""};
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: values => {
            handleSave(values);
        }
    });
    const handleSave = (values) =>{
        console.log(values)
        axios.post(`api/offers/create`, values).then(res => {
            if(res.data.success){
                if(res.data.data){
                    formik.setFieldValue("id",res.data.data.id);
                    formik.setFieldValue("amount",res.data.data.amount);
                }
                Swal.fire(res.data.message, '', 'success')               
            } 
            else{
                Swal.fire(res.data.message, '', 'error')
            }
        }).catch((error) => {
            // console.log(error.response.data)
            Swal.fire(error.response.data.message, '', 'error')
        });      
    }
    useEffect(() => {
        getList();
      }, []);

    const getList = async() => {
        setLoading(true)
        await axios.get('/api/offers')
        .then(function (response) {
        setLoading(false)
        let result = response.data;
            if(result.success){
                formik.setFieldValue("id",result.data.id);
                formik.setFieldValue("amount",result.data.amount);
            }
            else {
                setIsError(true);
            }
        })
    }
    return (
        <> 
        <Layout>
            <div className="row">
                    <div className="col-6">
                        <h1 className="display-4 ft-dark exprtl-page-title">Offers List</h1>
                    </div>
                </div>
                <div className="app-white-container">
                    <div className="row">
                        <form className="col-12" onSubmit={formik.handleSubmit}>
                            <div className="form-row">
                                <label className="col-sm-2 col-form-label"  htmlFor="name">New User Discount</label>
                                <input type="text" className="form-control col-sm-8" name="amount" value={formik.values.amount} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                                {formik.touched.amount && formik.errors.amount ? <div className="text-danger">{formik.errors.amount}</div> : null}
                                <label className="col-sm-1 col-form-label"  htmlFor="name"></label>
                                <button type="submit" className="form-control col-sm-1 btn btn-primary">Save</button>
                            </div>
                        </form>
                    </div>
                </div> 
        </Layout>
        </>
    );
}