import React, { useEffect, useState }  from 'react';
import PropTypes from "prop-types";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2'

export const Add = ({type, show, setShow, handleClose, getList, editId, setEditId, ...rest}) => {
    const [coupon, setCoupon] = useState('')    
    const [form, setForm] = useState({
        id: editId,
        coupon: coupon,
        expiresAt: '',
        percent: ''
    });

    useEffect(() => {
        if(editId > 0){
            axios.get(`api/singleDiscountCoupon?id=${editId}`).then(res => {
                console.log(res);
                if(res.data.success){
                    setCoupon(res.data.data.coupon)
                    setForm({...form, id: editId, coupon: res.data.data.coupon, expiresAt: datetimeLocal(res.data.data.expiresAt), percent: res.data.data.percent})
                }
            })
        }
        else{
            generateString(5).then(() => setForm({...form, id: editId, coupon: coupon, expiresAt: '', percent: ''})  );
        }
        
    },[editId]);
    
    const handleSave = () => {
        if(editId > 0){
            axios.put(`api/discountCoupons/update`, form).then(res => {
                if(res.data.success){
                    setShow(false);                    
                    Swal.fire(res.data.message, '', 'success').then(result => {
                        getList();                        
                    })
                } 
                else{
                    Swal.fire(res.data.message, '', 'error')
                }
            }).catch((error) => {
                Swal.fire(error.response.data.message, '', 'error')
            });
        }
        else{
            axios.post(`api/discountCoupons/create`, form).then(res => {
                if(res.data.success){
                    setShow(false);
                    Swal.fire(res.data.message, '', 'success').then(result => {
                        getList();
                        setForm({coupon: '', expiresAt: '', percent: ''})
                    })                
                } 
                else{
                    Swal.fire(res.data.message, '', 'error')
                }
            }).catch((error) => {
                Swal.fire(error.response, '', 'error')
            });
        }        
    }

    const datetimeLocal = (datetime) => {
        const dt = new Date(datetime);
        dt.setMinutes(dt.getMinutes() - dt.getTimezoneOffset());
        return dt.toISOString().slice(0, 16);
    }

    const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const generateString = async (length) => {
        return new Promise(function(resolve, reject){
            let result = '';
            const charactersLength = characters.length;
            for ( let i = 0; i < length; i++ ) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            result = result.replace(/\s+/g, '');
            if(result != ''){
                setCoupon(result);
                resolve(true);
            }
            else{
                reject(false);
            }
        })
        // return result;
    }

    return (
        <Modal show={show} onHide={handleClose} dialogClassName="modal-md" style={{zIndex: 9999}}>
            <Modal.Header>
                <h5 className="modal-title">{type} Discount Coupon</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </Modal.Header>
            <Modal.Body>
                <div className="form-group">
                    {/* <label htmlFor="" className='mr-5'>Coupon</label> */}
                    <span className='display-2 text-align-center'>{coupon}</span>
                    {/* <input name="coupon" type="text" className="form-control" value={coupon} onChange={(e) => setForm({...form, coupon : coupon})}/> */}
                </div>
                <div className="form-group">
                    <label htmlFor="">Expiry Date</label>
                    <input name="expiresAt" type="datetime-local" className="form-control" value={form.expiresAt } onChange={(e) => setForm({...form, expiresAt : e.target.value})} id="html5-date-input"/>
                </div>
                <div className="form-group">
                    <label htmlFor="">Percent (%)</label>
                    <input name="percent" type="text" className="form-control" value={form.percent } onChange={(e) => setForm({...form, percent : e.target.value})}/>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="r-btn r-btn-base" onClick={handleClose}>Close</button>
                <button type="button" onClick={handleSave} className="r-btn r-btn-primary">{type == 'Add' ? 'Add' : 'Update'}</button>
            </Modal.Footer>
        </Modal>
    );
}

export default Add;
Add.propTypes = {
    type: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired    
};