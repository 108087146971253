import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Layout } from '../Layout/Layout';
import axios from "axios";

export default function View() {  
    const navigate = useNavigate();
    const { userId } = useParams();
    const [user, setUser] = useState([]);

    useEffect(() => {
        if(userId !== undefined){
            axios.get(`/admin/findOne/${userId}`).then((res) =>{
                let result = res.data.data;
                if(res.data.success){
                    setUser(result);
                } 
            }).catch((error) => {
                console.log(error);
            });           
        }else{
            navigate(-1);
        }
      }, []);

    return (
    <>
    <Layout>
    <div class="row">
            <div class="col-6">
                <h1 class="display-4 ft-dark exprtl-page-title">View Provider</h1>
            </div>            
        </div>
        <div class="app-white-container">
            <div class="row">
                <div class="col-lg-3 col-sm-12">
                    <div class="avatar-upload">
                        <div class="avatar-edit">
                            {/* <input type='file' id="imageUpload" accept=".png, .jpg, .jpeg" /> */}
                            <label for="imageUpload"></label>
                        </div>
                        <div class="avatar-preview">
                            <div id="imagePreview" style={{backgroundImage: `url(${user.image === '' ? './assets/img/slide-1.jpg':user.image})`}}>
                            </div>
                        </div>                
                        <h1 class="display-4 ft-dark exprtl-page-title text-center mt-2">Upload Photo</h1>
                    </div>
                </div>
                <div class="col-lg-9  col-sm-12">        
                        <Link to={`/providers/view/${user.id}`} class="r-btn r-btn-primary invert float-right">
                            <i class="fa fa-pencil-alt"></i>
                            <span>Edit Profile</span>
                        </Link>
                    <h1 class="main-heading ft-secondary">{user.name}
                        <span class="icon ft-primary"><i class="fa fa-check-circle"></i></span>
                    </h1>
                    <p class="lead"><span class="icon icon-xs"><i class="fa fa-user-tie"></i></span> Sr ************
                    </p>
                    <p class="lead"><span class="icon icon-xs"><i class="fa fa-envelope"></i></span> {user.email}
                    </p>
                    <p class="lead"><span class="icon icon-xs"><i class="fa fa-phone"></i></span> {user.phone}</p>
                    
                    
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab"
                                aria-controls="home" aria-selected="true">About</a>
                        </li>
                        <li class="nav-item" role="presentation">
                            <a class="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab"
                                aria-controls="contact" aria-selected="false">Other Details</a>
                        </li>
                    </ul>
                    <div class="tab-content profile-content" id="myTabContent">
                        <div class="tab-pane block fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                            <div class="content px-2">
                                <p>{user.shortDescription}</p>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                            <table class="table table-striped">
                                <tbody>
                                    <tr>
                                        <th class="fw-600">Date of Birth</th>
                                        <td>{user.dateOfBirth}</td>
                                    </tr>
                                    <tr>
                                        <th class="fw-600">Gender</th>
                                        <td>{user.gender}</td>
                                    </tr>                                   
                                    <tr>
                                        <th class="fw-600">State</th>
                                        <td>Delhi</td>
                                    </tr>
                                    <tr>
                                        <th class="fw-600">Country</th>
                                        <td>India (IND)</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <h4 class="main-title">Specialities</h4>
                    <ul class="skills-wrap">
                        <li class="skill-item">PHP & MySql</li>
                        <li class="skill-item">HTML5 CSS3</li>
                        <li class="skill-item">jQuery</li>
                        <li class="skill-item">Bootstrap 4.5</li>
                    </ul>
                    <h4 class="main-title">Attchments & Certificate</h4>
                    <ul class="certificate-wrap">
                        <li class="certificate-item">
                            <div class="certificate-img"
                                style={{backgroundImage:"url('/assets/img/slide-2.jpg')"}}></div>
                            <h4 class="text-truncate">Certificate Title</h4>
                        </li>
                        <li class="certificate-item">
                            <div class="certificate-img"
                                style={{backgroundImage:"url('/assets/img/slide-2.jpg')"}}></div>
                            <h4 class="text-truncate">Certificate Title</h4>
                        </li>
                        <li class="certificate-item">
                            <div class="certificate-img"
                                style={{backgroundImage:"url('/assets/img/slide-2.jpg')"}}></div>
                            <h4 class="text-truncate">Certificate Title</h4>
                        </li>
                    </ul>
                </div>
            </div>
        </div> 
    </Layout>             

    </>
  );
}