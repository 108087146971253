import axios from 'axios';
import Swal from 'sweetalert2';

axios.defaults.baseURL = 'https://api.brightlife.space';
// axios.defaults.baseURL = 'https://apidev.brightlife.space';
// axios.defaults.baseURL = 'http://44.230.62.131:3007';
// axios.defaults.baseURL = 'http://localhost:3007';

axios.interceptors.request.use(
    async (config) => {
    const token = localStorage.getItem('token');
    if (token) {
        // config.headers.accessToken = token;
        config.headers= {"Content-Type": "application/json",'x-access-token':token};
    }
    else{
        config.headers= {"Content-Type": "application/json"};
    }
    return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);
axios.interceptors.response.use((response) => response, (error) => {
    if (error && (error.response.status === 401 || error.response.status === 403) ) {
        Swal.fire("Your session is expired! Login again to continue.")
        .then((value) => {
            localStorage.clear();        
            // window.location = window.location.origin+'/brightlife/admin/login';  //for testing on 44 server
            window.location = window.location.origin+'/login'; //for local
        });  
        
    }
});