import React, { useEffect, useState } from 'react';
import Table from '../Common/Table';
import { Layout } from '../Layout/Layout';
import { Add } from './Add';
import axios from 'axios';
import Swal from 'sweetalert2';
import Loader from '../Loader/Loader';

export default function List() {
    const [show, setShow] = useState(false);
    const [popupType, setPopupType] = useState('Add');    
    const [editId, setEditId] = useState(0);    
    const [isError, setIsError] = useState(false);    
    const [discounts,setDiscounts] = useState([]);
    const [loading, setLoading] = useState(false);
    const handleClose = () => setShow(false);

    useEffect(() => {
        getList();
      }, []);
    const getList = async() => {
        setLoading(true)
        await axios.get('/api/discounts')
        .then(function (response) {
        setLoading(false)
        let result = response.data;
            if(result.success){
                setDiscounts(result.data)
            }
            else {
                setIsError(true);
            }
        })
    }

    const handleAdd = () => {
        setEditId(0);
        setPopupType('Add');
        setShow(true);
    }

    const handleEdit = (id) => {
        setEditId(id);
        setPopupType('Edit');
        setShow(true);
    }

    const handleDelete = (id) => {
        Swal.fire({
            title: 'Are you sure you want to delete?',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) { 
                axios.delete(`/api/discounts/delete?id=${id}`).then((res) =>{
                    if(res.data.success){                             
                        Swal.fire('Deleted!', '', 'success')           
                        setDiscounts(discounts.filter((v, i) => v.id !== id));
                    } 
                }).catch((error) => {
                    console.log(error);                     
                    Swal.fire(error.message, '', 'info')           
                });
            } else if (result.isDenied) {
              Swal.fire('Cancelled!', '', 'info')
            }
        }) 
    }

    const handleStatus = (e, id) => {       
        var status = '1';
        if(e.target.checked === true) {
            status = '1'
        }
        else {
            status = '0'
        }
        axios.put(`api/discounts/updateStatus?id=${id}`, {status}).then(res => {                
        })
        .catch(err => {
        })
    }
    const columns = [
        {
            name: 'Amount',
            selector: row => (
                <p>₹ {row.amount}</p>
            ),
            sortable: true,
        },
        {
            name: 'Percent',
            selector: row => (
                <p>{row.percent} %</p>
            ),
            sortable: true,
        },
        {
            name: 'Status',
            cell: row => (
                <div>
                    <input className="tgl tgl-light toggle_discounts" name="status"  id={"chk_"+row.id} type="checkbox"   onChange={(e) => handleStatus(e, row.id)}  defaultChecked={row.status == '1'?true:false}/>
                    <label title="Active" className="tgl-btn mt-1" htmlFor={"chk_"+row.id} ></label>
                </div>
            ),
        },
        {
            name: 'Action',
            button: true,
            cell: row => (
                <div>
                    <a style={{color:'white'}} className="icon icon-sm icon-secondary ml-1" onClick={() => handleEdit(row.id)}>
                        <i className="fa fa-pencil-alt" data-toggle="tooltip" data-placement="right" title="" data-original-title="Edit"></i>
                    </a>
                    <a href="#" className="icon icon-sm icon-primary ml-1"  onClick={() => handleDelete(row.id)}>
                        <i className="fa fa-times" data-toggle="tooltip" data-placement="right" title="" data-original-title="Delete"></i>
                    </a>
                </div>
            ),
        }
    ];
    return (
        <>
        {loading ? <Loader/> : ''};
            <Layout>  
                <div className="row">
                    <div className="col-6">
                        <h1 className="display-4 ft-dark exprtl-page-title">Discounts List</h1>
                    </div>
                    <div className="col-6 text-right">
                        <a href="javascript: void(0)" className="r-btn r-btn-primary mt-1" onClick={()=>handleAdd()}>
                            <i className="fa fa-plus"></i><span>Add Discount</span>
                        </a>
                    </div>
                </div>
                <div className="app-white-container">
                    <div className="row">
                        <Table  columns={columns} data={discounts}/>
                    </div>
                </div>  
            </Layout>
            <Add type={popupType} show={show} setShow={setShow} handleClose={handleClose} getList={getList} editId={editId} setEditId={setEditId}/>
        </>
    )

}