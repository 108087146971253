import React, { useEffect, useRef, useState } from 'react';
import { Layout } from '../Layout/Layout';
import { useNavigate, useParams } from 'react-router-dom';
import axios from "axios";
import Swal from 'sweetalert2';
import { useFormik } from 'formik';
import S3 from 'react-aws-s3';

window.Buffer = window.Buffer || require("buffer").Buffer; 
 
const validateForm = data => {
    const errors = {};  
    if (!data.name) {
        errors.name = 'Please enter Name';
    }
    if (!data.email) {
        errors.email = 'Please enter Email ID';
    }
    if (!data.phone) {
        errors.phone = 'Please enter Phone Number';
    }
    if (!data.gender) {
        errors.gender = 'Please select Gender';
    }
    if (!data.dateOfBirth) {
        errors.dateOfBirth = 'Please select Date of Birth';
    }    
    return errors;
};
 
export default function Add() {
    const imageInput = useRef();
    const navigate = useNavigate();
    const { userId } = useParams(); 
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [form, setForm] = useState({
        name: '',
        gender: '',
        dateOfBirth: '',
        email: '',
        phone: '',
        shortDescription:'',
        image:'',
        role: 'Client'
    });
    const [isEmailExist, setIsEmailExist] = useState(false);
    const [isPhoneExist, setIsPhoneExist] = useState(false);
    const [isToggle, setIsToggle] = useState(false);
 
    useEffect(() => {
        if(userId !== undefined){
            axios.get(`/api/user?id=${userId}`).then((res) =>{         
                if(res.data.success){
                    setformikValues(res.data.data);
                }
            }).catch((error) => {
                console.log(error);
            });          
        }
        console.log(formik.values.image)
    }, []);

    const setformikValues = (result) => {
        console.log(result)
        formik.values.name = result.name;
        formik.values.email = result.email;
        formik.values.phone = result.phone;
        formik.values.image = result.image;
        formik.values.gender = result.user_detail.gender;
        formik.values.dateOfBirth = result.user_detail.dateOfBirth;
        setForm(formik.values);
    }
 
    const formik = useFormik({
        initialValues: form,
        validate: validateForm,
        onSubmit: values => {
            handleSubmit(values);            
        }
    });

    const handleImageChange = (e) => {
        let file = e.target.files[0];
        const config = {
            bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
            // dirName: process.env.REACT_APP_DIR_NAME /* optional */,
            region: process.env.REACT_APP_S3_REGION,
            accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
            secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY
        };
        const ReactS3Client = new S3(config);
        const newFileName = 'P'+Math.floor(
            Math.random() * (99999999 - 10000000 + 1) + 10000000
        );
        ReactS3Client.uploadFile(file, newFileName)
            .then(data => {
                if(data.status === 204){
                    formik.values.image = data.location;
                    setForm({...form, image: data.location});
                }
                else{
                    console.log('error')
                }
                
            })
            .catch(err => console.error(err))
    }

    const handleSubmit = (values) => {
        setIsSubmitted(true);
        var opertation = userId ? 'Edit' : 'Add';
        const addUpdateUser = userId ? axios.put(`/admin/clients/${userId}`, values) : axios.post('/admin/clients', values);
        
        addUpdateUser.then((res) =>{
            if(res.data.success){
                Swal.fire(res.data.message, '', 'success').then(result => {
                    if(opertation == 'Add'){
                        navigate("/clients")
                    }
                    else{
                        navigate("/clients/edit/"+userId)
                    }                    
                })                
            }
            else{
                Swal.fire(res.data.message, '', 'info')
            }
        }).catch((error) => {
            Swal.fire(error.message, '', 'info')        
        });
    }

    const checkEmail = (e) => {
        if(e.target.value != ''){
            axios.get(`/admin/checkEmail?email=${e.target.value}&id=${userId}`).then((res) =>{
                if(res.data.success){
                    setIsEmailExist(true);
                }
                else{
                    setIsEmailExist(false)
                }
            }).catch((error) => {
                console.log('error===',error.message);        
            });
        }        
    }

    const checkPhone = (e) => {
        if(e.target.value != ''){
            axios.get(`/admin/checkPhone?phone=${e.target.value}&id=${userId}`).then((res) =>{
                if(res.data.success){
                    setIsPhoneExist(true);
                }
                else{
                    setIsPhoneExist(false)
                }
            }).catch((error) => {
                console.log('error===',error.message);        
            });
        }        
    }
 
    return (
    <>
    <Layout>        
        <div className="row">
            <div className="col-6">
                <h1 className="display-4 ft-dark exprtl-page-title">{userId ?'Edit':'Add'} Client</h1>
            </div>
        </div>
        <div className="app-white-container">        
            <div className="row">
                <div className="col-lg-12">
                    <div className="tab-content border" id="r-pills-tabContent">
                        {/* <!-- PERSONAL INFO --> */}
                        <div className="block transparent tab-pane fade show active" id="r-pills-home" role="tabpanel" aria-labelledby="r-pills-home-tab">
                            <div className="header">
                                <h4 className="main-title ft-acent">Personal Information</h4>
                            </div>
                            <form onSubmit={formik.handleSubmit}>
                                <div className="content">
                                    <div className="alert alert-danger alert-white rounded" style={{ display: "none",border:'0px' }}></div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="avatar-upload">
                                                <div className="avatar-edit">
                                                    <input type='file' id="imageUpload" accept=".png, .jpg, .jpeg" name="image" ref={imageInput} onChange={handleImageChange}/>
                                                    <label htmlFor="imageUpload"></label>
                                                </div>
                                                <div className="avatar-preview">
                                                    <div id="imagePreview" style={{backgroundImage: formik.values.image != '' ? "url('"+formik.values.image+"')"  : "url('https://brightlifeapp.s3.us-west-2.amazonaws.com/default-avatar.png')"}}> </div>
                                                </div>                
                                                <h1 className="display-4 ft-dark exprtl-page-title text-center mt-2">Upload Photo</h1>
                                            </div>
                                        </div>   
                                        <div className="col-md-8">
                                            <div className="row">
                                                <div className="col-md-6 form-group">
                                                    <label htmlFor="firstNameInp">First Name</label>
                                                    <input type="text" className="form-control" name="name" defaultValue={formik.values.name} onChange={formik.handleChange} onBlur={formik.handleBlur}/> 
                                                    {formik.touched.name && formik.errors.name ? <div className="text-danger">{formik.errors.name}</div> : null}
                                                </div>
                                                <div className="col-md-6 form-group">
                                                    <label htmlFor="">Email</label>
                                                    <input type="email" className="form-control" name="email" onBlur={checkEmail}  onChange={formik.handleChange} defaultValue={formik.values.email}/>
                                                    {isEmailExist && (<p className="text-danger">Email already Exists!</p>)}
                                                    {formik.touched.email && formik.errors.email ? <div className="text-danger">{formik.errors.email}</div> : null}
                                                </div>
                                                <div className="col-md-6 form-group">
                                                    <label htmlFor="">Phone Number</label>
                                                    <input type="number" className="form-control" name="phone" onBlur={checkPhone}  onChange={formik.handleChange} defaultValue={formik.values.phone}/>
                                                    {isPhoneExist && (<p className="text-danger">Phone number already Exists!</p>)}
                                                    {formik.touched.phone && formik.errors.phone ? <div className="text-danger">{formik.errors.phone}</div> : null}
                                                </div>
                                                <div className="col-md-6 form-group">
                                                    <label htmlFor="genderInp">Gender</label>
                                                    <select id="genderInp" className="form-control" name="gender" defaultValue={formik.values.gender} value={formik.values.gender} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                        <option value="">Please Select</option>
                                                        <option value="Male">Male</option>
                                                        <option value="Female">Female</option>
                                                        <option value="Other">Other</option>
                                                    </select>
                                                    {formik.touched.gender && formik.errors.gender ? <div className="text-danger">{formik.errors.gender}</div> : null}
                                                </div>
                                                <div className="col-md-6 form-group">
                                                    <label htmlFor="">Date of Birth</label>
                                                    <input type="date" className="form-control" name="dateOfBirth"  onChange={formik.handleChange} onBlur={formik.handleBlur} defaultValue={formik.values.dateOfBirth}/>
                                                   
                                                    {formik.touched.dateOfBirth && formik.errors.dateOfBirth ? <div className="text-danger">{formik.errors.dateOfBirth}</div> : null}
                                                </div>
                                                <div className="col-md-6 form-group">
                                                    <label htmlFor="">Wallet Balance</label>
                                                    <input type="number" className="form-control" name="balance" onBlur={checkPhone}  onChange={formik.handleChange} defaultValue={formik.values.balance}/>
                                                    {formik.touched.balance && formik.errors.balance ? <div className="text-danger">{formik.errors.balance}</div> : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="footer text-right ">
                                    <button type='submit' style={{color:'white'}} className="r-btn r-btn-secondary ml-2" disabled={isEmailExist || isPhoneExist ? true : false}>Save Details</button>
                                </div>
                            </form>  
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
    </>
  );
}
