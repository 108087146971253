import React, { useEffect, useState } from 'react';
import Table from '../Common/Table';
import { Layout } from '../Layout/Layout';
import axios from 'axios';
import { Info } from './Info';
import { CSVLink } from "react-csv";
import AddSession from './AddSession';
import Loader from '../Loader/Loader';

export default function List() {
    const [bookings, setBookings] = useState([]);
    const [csvData, setCsvData] = useState([]);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [bookingInfo, setBookingInfo] = useState([]);
    
    const [showSession, setShowSession] = useState(false);
    const handleCloseSession = () => setShowSession(false);
    const [popupType, setPopupType] = useState('Add');
    const [editId, setEditId] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getList();
      }, []);

    const getList = async () => {
        setLoading(true)
        await axios.get('/api/bookings')
        .then(function (response) {
            setLoading(false)
            let result = response.data;
            if(result.success){
                setBookings(result.data)
                let csvData = []
                result.data.map( (bookingData) => {
                    csvData.push({
                        // id: bookingData.id, 
                        type: bookingData.type, 
                        clientName: bookingData.user.name, 
                        ProviderName: bookingData.provider.name,
                        seek: bookingData.type === 'Session' ? "-" : bookingData.seek.name,
                        noOfSessions: (bookingData.type === 'Session' && bookingData.user_session_plan !== null) ? bookingData.user_session_plan.noOfSessions :"-" ,
                        bookingDate: bookingData.createdAt,
                        status: bookingData.status,
                    })
                })
                // console.log("csvData=",csvData)
                setCsvData(csvData)
            }
        })
    }
    const showInfo = (bookingId) => {
        setShow(true);
        const result = bookings.filter(x => x.id === bookingId);
        setBookingInfo(result[0]);
    }

    const handleAdd = () => {
        setEditId(0);
        setPopupType('Add');
        setShowSession(true);
    }
    
    const columns = [
        {
            name: 'Type',
            selector: row => row.type,
            sortable: true,
        },
        {
            name: 'Client Name',
            selector: row => row.user.name,
            sortable: true,
        },
        {
            name: 'Provider Name',
            selector: row => row.provider.name,
            sortable: true,
        },
        {
            name: 'Seek',
            selector: row => row.type === 'Session' ? "-" : row.seek.name,
        },
        {
            name: 'Booking Date',
            selector: row => row.createdAt,
        },
        {
            name: 'Status',
            cell: row => (
                <div>
                    { row.status === 'Pending' ? <span className="ml-1 text-warning"> {row.status} </span> : "" }
                    { row.status === 'Accepted' ? <span className="ml-1 text-success"> {row.status} </span> : "" }
                    { row.status === 'Rejected' ? <span className="ml-1 text-danger"> {row.status} </span> : "" }
                    { row.status === 'Completed' ? <span className="ml-1 text-muted"> {row.status} </span> : "" }                    
                </div>
            ),
        },
        {
            name: 'Action',
            cell: row => (
                <div>
                    {row.type === 'Session' ? 
                        <span className="icon icon-sm icon-secondary ml-1 float-right"  onClick={()=>showInfo(row.id)}>
                            <i className="fa fa-info" title="More Info"></i>
                        </span> : ""
                    }                    
                </div>
            ),
        }
    ];
        
    return (
        <>
         {loading ? <Loader/> : ''};
            <Layout>  
                <div className="row">
                    <div className="col-8">
                        <h1 className="display-4 ft-dark exprtl-page-title">Bookings List</h1>
                    </div>
                    <div className="col-2 text-right">
                        <a className="r-btn r-btn-primary mt-1 text-white" onClick={()=>handleAdd()}>
                            <i className="fa fa-plus"></i><span>Create Session Booking</span>
                        </a>
                    </div>
                    <div className="col-2 text-right">
                        <CSVLink filename={"Bookings.csv"} data={csvData} className="r-btn r-btn-primary mt-1" >
                            <i className="fa fa-download"></i><span>Export Bookings</span>
                        </CSVLink>
                    </div>                    
                </div>
                <div className="app-white-container">
                    <div className="row">
                        <Table columns={columns} data={bookings}/>
                    </div>
                </div>                
            </Layout> 
            <Info show={show} setShow={setShow} handleClose={handleClose} bookingInfo={bookingInfo} getList={getList} loading={loading} setLoading={setLoading}/> 
            <AddSession type={popupType} show={showSession} setShow={setShowSession} handleClose={handleCloseSession} getList={getList}/>
        </>
    )

}