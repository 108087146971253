import React, { useEffect, useState }  from 'react';
import PropTypes from "prop-types";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2'

export const AddEducation = ({type, userId, show, setShow, handleClose, getList, editId, setEditId, ...rest}) => {    
    const [form, setForm] = useState({
        id: editId,
        userId: userId,
        name: ''
    });

    useEffect(() => {
        if(editId > 0){
            axios.get(`/admin/providers/education?id=${editId}`).then(res => {
                console.log(res);
                if(res.data.success){
                    setForm({...form, id: editId, userId: userId, name: res.data.data.name})
                }
            })
        }
        else{
            setForm({...form, id: editId, userId: userId, name: ''})
        }
        
    },[editId]);
    
    const handleSave = () =>{
        if(editId > 0){
            axios.put(`/admin/providers/educations/edit/${editId}`, form).then(res => {
                if(res.data.success){
                    setShow(false);                    
                    Swal.fire(res.data.message, '', 'success').then(result => {
                        getList();                        
                    })
                } 
                else{
                    Swal.fire(res.data.message, '', 'error')
                }
            }).catch((error) => {
                // console.log(error.response.data)
                Swal.fire(error.response.data.message, '', 'error')
            });
        }
        else{
            axios.post(`admin/providers/educations/add`, form).then(res => {
                if(res.data.success){
                    setShow(false);
                    Swal.fire(res.data.message, '', 'success').then(result => {
                        getList();
                        setForm({...form, name: ''})
                    })                
                } 
                else{
                    Swal.fire(res.data.message, '', 'error')
                }
            }).catch((error) => {
                // console.log(error.response.data)
                Swal.fire(error.response.data.message, '', 'error')
            });
        }        
    }
    return (
        <Modal show={show} onHide={handleClose} dialogClassName="modal-md" style={{zIndex: 9999}}>
            <Modal.Header>
                <h5 className="modal-title">{type} Education</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </Modal.Header>
            <Modal.Body>
                <div className="form-group">
                    <label htmlFor="">Name</label>
                    <input name="name" type="text" className="form-control" value={form.name} onChange={(e) => setForm({...form, name: e.target.value})}/>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="r-btn r-btn-base" onClick={handleClose}>Close</button>
                <button type="button" onClick={handleSave} className="r-btn r-btn-primary">{type == 'Add' ? 'Add' : 'Update'}</button>
            </Modal.Footer>
        </Modal>
    );
}

export default AddEducation;
AddEducation.propTypes = {
    type: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired    
};