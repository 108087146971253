import React, { useState } from 'react';
import PropTypes from "prop-types";
import axios from "axios";
export const Attachments = ({user, ...rest}) => {
    
    const initialData = {
        fileName:'',
        attachment:'',
        userId:12,
    }    
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [form, setForm] = useState(initialData);

    function updateAttachmentForm(e){
        setForm({
            ...form,
            [e.target.name]: e.target.value,
          });
    }
    function addAttachment(form){
        console.log('form==',form);
        axios.post('addAttachment', form);
    }

    function handleSubmit(){
        alert('Coming Soon!')
        // setIsSubmitted(true)
        // addAttachment(form).then((res) =>{
        //     console.log("data Addedd",res)   
        //     console.log('form==',form);
        // }).catch((error) => {
        //     console.log(error);
        // });
    }
    return (
        <div className="modal" id="add_certificate" tabIndex="-1" style={{zIndex: 9999}}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <form action="">
                        <div className="modal-header">
                            <h5 className="modal-title">Add Documents</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <label htmlFor="">Upload File</label>
                                <input type="file" className="form-control" name="attachment" onChange={updateAttachmentForm}/>                                
                                {isSubmitted && !form.attachment.trim() && (
                                    <p className="text-danger">This field is required</p>
                                )}
                            </div>
                            <div className="form-group">
                                <label htmlFor="">File Name</label>
                                <input type="text" className="form-control" name="fileName" onChange={updateAttachmentForm}/>
                                {isSubmitted && !form.fileName.trim() && (
                                    <p className="text-danger">This field is required</p>
                                )}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="r-btn r-btn-base" data-dismiss="modal">Close</button>
                            <button type="button" className="r-btn r-btn-primary" onClick={handleSubmit}>Save Details</button>
                        </div>
                    </form>
                </div>
            </div>
        </div> 
    );
}

export default Attachments;
Attachments.propTypes = {
    user: PropTypes.string
  };