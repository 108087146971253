import React from 'react';
import PropTypes from 'prop-types';

const PrivacyPolicy = props => {
    return (
        <div class="container-fluid">
            <div class="d-flex justify-content-center mb-3">
                {/* <img src="https://admin.brightlife.space/assets/img/brightlife-logo.png" alt="" height="75px"/> */}
                <img src={process.env.REACT_APP_PUBLIC_URL+"assets/img/brightlife-logo.png"} alt="" height="75px"/>
            </div>
            <div class="card p-4">
                <div class="privacy-wrapper">
                    <div class="container-fluid">
                        <h2>Brightlife Privacy Policy</h2>
                        <p>Brightlife ("Company") values your privacy. In this Privacy Policy ("Policy"), we describe the information that we collect about you when you visit the Company's platform ("Brightlife") and use the services available on Brightlife and how we use and disclose that information.</p>
                        <p>This Policy is incorporated into and is subject to the Brightlife Terms of Use. Your use of Brightlife and any personal information you provide on Brightlife remains subject to the terms of the Policy and Brightlife Terms of Use. If you have any questions or comments about the Privacy Policy, please contact us at care@brightlife.space.</p>
                        <hr/>
                        <div class="row">
                            <div class="col-md-12">
                                <h4>A. Collection of Your Personal Information</h4>
                                <h6>1. COLLECTION OF PERSONAL INFORMATION</h6>
                                <p>"Personal information" is defined as including information that, whether on its own or in combination with other information, may be used to readily identify or contact you, such as name, email address, phone number, etc.</p>
                                <p>"Sensitive personal data or information" or "SPDI" for the purpose of the User's chats with the Coaches (as defined in the Terms of Use) is defined to include the following:</p>
                                <ul>
                                    <li>(i) physical, physiological and mental health conditions;</li>
                                    <li>(ii) sexual orientation;</li>
                                    <li>(iii) medical history and records;</li>
                                    <li>(iv) any detail concerning the above as provided to the Company for providing the service; and</li>
                                    <li>(v) any information received under (i) to (iv) above for processing or storing such information under a lawful contract or otherwise.</li>
                                </ul>
                                <p>SPDI shall not include any such information freely available or accessible in the public domain or furnished under the Right to Information Act, 2005, or any other law for the time being in force.</p>
                                <p>We collect personal information from users desirous of using Brightlife, including availing the Coach sessions and posting Submitted Content (as defined in the Terms of Use) on Brightlife. This information is partially or completely accessible to all visitors using Brightlife (other than information/proceedings between a User and a Coach). Users are required to create an account to access certain portions of Brightlife.</p>
                                <p>When creating the Account, user data such as name, email ID, etc., shall be collected and the User grants requisite permission for the same. Such information gathered shall be utilized to ensure greater customer satisfaction and help a customer satiate its needs. The type of personal information that we collect from you varies based on your particular interaction with the Company.</p>
                                <p>We may also collect data concerning your reactions on the thoughts and replies through emojis, your postings in Sub-communities, your status updates, etc., to optimize Brightlife and provide curated experience to each User.</p>
                                <p>We may also collect personal information that you post in your Submitted Content such as profile, feedback, and any comments or discussions you post in any blog, forum, or other correspondence site on Brightlife. The Company reserves the right to record the conversations through the messaging/chat mechanism on the platform. All the chat/messaging logs can be used for, but are not limited to, monitoring and prohibiting abuse, safeguarding the users' rights, resolving disputes, etc.</p>
                                <p>We may also collect data concerning your reactions on the thoughts and replies through emojis, your postings in Sub-communities, your status updates, etc., to optimize Brightlife and provide a curated experience to each User.</p>
                                <p>We shall also collect information concerning the number of Coach sessions availed by you, details of the Coach, date and time slot of the sessions, any SPDI shared over chat with the Coach. It is however clarified notwithstanding anything else contained in this Policy, the Company shall not be privy to the proceedings of the Coach sessions itself and the same shall be confidential as per applicable law.</p>
                                <h6>2. COLLECTION OF AUTOMATIC INFORMATION, USE OF COOKIES AND OTHER TRACKING DEVICES</h6>
                                <p>We and our third-party service providers, which include ad networks, use cookies, web beacons, and other tracking technologies to collect information about your use of Brightlife, such as your browser type, your ISP or operating system, your domain name, your access time, the URL of the previous website you visited, your page views, your IP address, and the type of device that you use. We also track how frequently you visit Brightlife. We use this information (including the information collected by our third-party service providers) for website analytics (including to determine which portions of Brightlife are used most frequently, what our users like/do not like), to assist us in determining relevant advertising (both on and off our Brightlife), to evaluate the success of our advertising campaigns, and as otherwise described in this Policy. Currently, we do not honour browser requests not to be tracked.</p>
                                <p>We may obtain your device ID, which is sent to the Company's servers and used in fraud prevention efforts.</p>
                                <p>Cookies: We and our third-party service providers collect information from you by using cookies. A cookie is a small file stored on User's computer hard drive containing information about the User. The cookie helps us analyse web traffic or informs you about your use of a particular website. Cookies allow web applications to respond to you as an individual, tailoring its operations to your needs, likes and dislikes by gathering and remembering information about your preferences. When you visit Brightlife, we may send one or more cookies (i.e., a small text file containing a string of alphanumeric characters) to your computer that identifies your browser.</p>
                                <p>Some of these cookies may be connected to third-party companies or websites. The terms of use of such cookies are governed by this Policy and the privacy policy of the relevant third-party Company or website. For example, Google measures the performance of advertisements by placing cookies on your computer when you click on ads. If you visit Brightlife when you have such cookies on your computer, we and Google will be able to tell that you saw the ad delivered by Google. The terms of use of these cookies are governed by this Policy and Google's Privacy Policy.</p>
                                <p>Disabling Cookies: You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you disable cookies, you may be prevented from taking full advantage of Brightlife because it may not function properly if the ability to accept cookies is disabled.</p>
                                <p>Clear GIFs, pixel tags and other technologies: Clear GIFs are tiny graphics with a unique identifier, similar in function to cookies. In contrast to cookies, which are stored on your computer's hard drive, clear GIFs are embedded invisibly on web pages. We may use clear GIFs (a.k.a. web beacons, web bugs or pixel tags), in connection with Brightlife to, among other things, track the activities of Brightlife visitors, help us manage content, and compile statistics about Brightlife usage. We and our third-party service providers also use clear GIFs in HTML emails to our Visitors, to help us track email response rates, identify when our emails are viewed, and track whether our emails are forwarded.</p>
                            </div>
                        </div>
                        <hr/>
                        <div class="row">
                            <div class="col-md-12">
                                <h4>B. How the Company Uses the Information We Collect</h4>
                                <h6>1. HOW PERSONAL INFORMATION AND SPDI IS USED</h6>
                                <p>We collect your personal information and and SPDI aggregate information about the use of Brightlife to better understand your needs and to provide you with a better Brightlife experience. Specifically, we may use your personal information for any of the following reasons:</p>                               
                                <ul>
                                    <li>•	To provide our services to you, including registering you for our services, verifying your identity and authority to use our services, and to otherwise enable you to use Brightlife</li>
                                    <li>•	For customer support and to respond to your inquiries.</li>
                                    <li>•	For internal record-keeping purposes.</li>
                                    <li>•	To process billing and payment, including sharing with third party payment gateways in connection with Brightlife</li>
                                    <li>•	To improve and maintain Brightlife (for example, we track information entered through the "Search" function, this helps us determine which areas of Brightlife users like best and areas that we may want to enhance; we also will use for trouble-shooting purposes, where applicable).</li>
                                    <li>•	To periodically send promotional emails to the email address you provide regarding new products on Brightlife, special offers on Brightlife or other information about Brightlife that we think you may find interesting.</li>
                                    <li>•	To contact you via email, telephone, facsimile or mail, or, where requested, by text message, to deliver certain services or information you have requested.</li>
                                    <li>•	For the Company's market research purposes, including, but not limited to, the customization of Brightlife according to your interests.</li>
                                    <li>•	We may use your demographic information (i.e., age, and other various data) to more effectively facilitate the promotion of goods and services to appropriate target audiences and for other research and analytical purposes;</li>
                                    <li>•	To resolve disputes, to protect ourselves and other users of Brightlife, and to enforce our Terms of Use;</li>
                                    <li>•	To resolve any dispute/ complaint raised by you against the Coach;</li>
                                    <li>•	To help concerned authorities in case an Coach raises a complaint against you and is desirous of initiating appropriate legal action;</li>
                                    <li>•	To intimate authorities if the Coach is of the view that you may cause harm to either yourself or others;</li>
                                    <li>•	We also may compare personal information collected through Brightlife to verify its accuracy with personal information collected from third parties</li>
                                    <li>•	We may combine aggregate data with the personal information we collect about you.</li>
                                </ul>
                                <p>It is clarified that the use of your SPDI for the above purposes occurs only after the Company has anonymized the SPDI i.e., you are not identifiable from the SPDI you have shared with the Coach over chat.</p>
                                <p>From time to time, the Company may use personal information and SPDI for new and unanticipated uses not previously disclosed in our Privacy Policy. If our information practices change regarding information previously collected, the Company shall make reasonable efforts to provide notice and obtain consent of any such uses as may be required by law.</p>
                                <p>It is further clarified that your registration on Brightlife shall be deemed to be your consent to be contacted for the purposes mentioned above, (i) on the mobile number shared by you even if you are registered with the National Customer Preference Register (NCPR) and have opted out of receiving promotional calls and messages and (ii) by way of SMS or email notifications or messages in any other electronic form.</p>
                            </div>
                        </div>
                        <hr/>
                        <div class="row">
                            <div class="col-md-12">
                                <h4>C. Electronic Newsletters, Invitations, Polls and Surveys</h4>
                                <p>At our sole discretion, the Company may offer any of the following free services on Brightlife, which you may select to use or receive at your option. Certain of the following services may require you to provide additional personal information as detailed below</p>
                                <h6>1. ELECTRONIC NEWSLETTERS</h6>
                                <p>We may offer a free electronic newsletter to users. We will gather the email addresses of users who sign up on Brightlife for the newsletter mailing list. Users may remove themselves from this mailing list by opting out of receiving newsletters during the registration process, by following the link provided in each newsletter that points users to a subscription management page where the User can unsubscribe from receiving newsletters or by changing their preferences in their profile settings page.</p>                              
                                <h6>2. SEND TO A FRIEND</h6>
                                <p>Brightlife users can voluntarily choose to electronically forward a link, page, or document to someone else by clicking "send." To do so, the User must provide his or her email address, as well as the email address of the recipient. The User's email address is used only in the case of transmission errors and, of course, to let the recipient know who sent the email. The information is not used for any other purpose.</p>   
                                <h6>3. POLLING</h6>
                                <p>We may offer interactive polls to users so they can easily share their opinions with other users and see what our audience thinks about important issues, services, and/or Brightlife. Opinions or other responses to polls are aggregated and are not identifiable to any particular user. We may use a system to "tag" users after they have voted, so they can vote only once on a particular question. This tag is not correlated with information about individual users.</p>   
                                <h6>4. SURVEYS</h6>
                                <p>We may conduct user surveys from time to time to better target our content to our Brightlife users. We will not share individual responses from these surveys with any third party. We will share aggregate data with third party service providers, partners, and other third parties. We also will post aggregate data containing survey responses on Brightlife; that data may be viewed and downloaded by other users of Brightlife.</p>   
                            </div>
                        </div>
                        <hr/>
                        <div class="row">
                            <div class="col-md-12">
                                <h4>D. Security</h4>
                                <p>We employ procedural and technological security measures, which are reasonably designed to help protect your personal information from unauthorized access or disclosure. The Company may use encryption, passwords, and physical security measures to help protect your personal information against unauthorized access and disclosure. No security measures, however, are 100% complete. Therefore, we do not promise and cannot guarantee, and thus you should not expect, that your personal information, SPDI or private communications will not be collected and used by others. You should take steps to protect against unauthorized access to your password, phone, and computer by, among other things, signing off after using a shared computer, choosing a robust password that nobody else knows or can easily guess, and keeping your log-in and password private. The Company is not responsible for the unauthorized use of your information or for any lost, stolen, compromised passwords, or for any activity on your Account via unauthorized password activity.</p>
                                <p>Your password is the key to your Account. When creating your password you should use unique numbers, letters, special characters, and combinations thereof. In addition, DO NOT disclose your password to anyone. If you do share your password or your personal information with others, you are solely responsible for all actions taken via your Account. If you lose control of your password, you may lose substantial control over your personal information and be subject to legally binding actions taken on your behalf. Thus, IF YOUR PASSWORD HAS BEEN COMPROMISED FOR ANY REASON, YOU MUST IMMEDIATELY NOTIFY THE COMPANY TO CHANGE YOUR PASSWORD.</p> 
                            </div>
                        </div>
                        <hr/>                       
                         <div class="row">
                            <div class="col-md-12">
                                <h4>E. Disclosure</h4>
                                <p>We may share the information that we collect about you, including your personal information, as follows:</p>
                                <h6>1. INFORMATION DISCLOSED TO PROTECT US AND OTHERS</h6>
                                <p>We may disclose your information including Personal Information and SPDI if:</p>                               
                                <ul>
                                    <li>(i) the Company reasonably believes that disclosure is necessary in order to comply with a legal process (such as a court order, search warrant, etc.) or other legal requirement of any governmental authority,</li>
                                    <li>(ii) disclosure would potentially mitigate our liability in an actual or potential lawsuit,</li>
                                    <li>(iii) reasonably necessary to enforce this Privacy Policy, our Terms of Use etc.</li>
                                    <li>(iv) disclosure is intended to help investigate or prevent unauthorized transactions or other illegal activities, or</li>
                                    <li>(v) necessary or appropriate to protect our rights or property, or the rights or property of any person or entity.</li>
                                </ul>
                                <p></p>
                                <h6>2. INFORMATION DISCLOSED TO THIRD PARTY SERVICE PROVIDERS AND BUSINESS PARTNERS</h6>
                                <p>We may contract with various third parties for the provision and maintenance of Brightlife and our business operations, and the Company may need to share your personal information, SPDI and data generated by cookies and aggregate information (collectively, "information") with these vendors and service agencies. For example, we may provide your information to a credit card processing company to process your payment. The third parties will not receive any right to use your personal information beyond what is necessary to perform its obligations to provide the services to you. If you complete a survey, we also may share your information with the survey provider; if we offer a survey in conjunction with another entity, we also will disclose the results to that entity.</p>
                                <h6>3. DISCLOSURE TO OTHER USERS OF BRIGHTLIFE</h6>
                                <p>The publicly available information that you post (other than your payment information) is available to other users of Brightlife. Comments that users post to Brightlife also will be available to other visitors to Brightlife (see our discussion of User Generated Content below). In addition, we will post the results (in aggregate form) of surveys to Brightlife.</p>
                                <p>You will be provided an option on the Brightlife app to join different Sub-communities (as defined under Terms of Use). You cannot join the Sub-communities anonymously and your username will be visible to all the Users of a particular Sub-community.</p>
                                <p>Brightlife provides for a live button on the Brightlife app. You shall be shown as 'live' to the other Users on the Brightlife app when you engage in any activity on the app including reading, posting, replying, reacting etc. While browsing through the feed, you can also see if any other User is live on the app. However, you will not be shown 'live' on the app if you are posting/ commenting anonymously.</p>
                                <p>You will be able to react to 'thoughts and replies' of the other Users through emoji of you are a non-anonymous User. The other Users will be able to see your username once you have reacted to a 'thought or reply'.</p>
                                <p>You will also be able to update your status on the Brightlife app if you are a non-anonymous User. Your status update will be visible to other Users on the feed and on your profile.</p>
                                <h6>4. INFORMATION DISCLOSED TO LAW ENFORCEMENT OR GOVERNMENT OFFICIALS UNDER APPLICABLE LAW</h6>
                                <p>We will disclose your information, including, without limitation, your name, city, state, telephone number, email address, user ID history, quoting and listing history, and complaints, to law enforcement or other government officials if we are required to do so by law, regulation or other government authority or otherwise in cooperation with an investigation of a governmental authority.</p>
                                <h6>5. IN THE EVENT OF A CHANGE OF CONTROL OR BANKRUPTCY</h6>
                                <p>In the event that the Company undergoes a change in control, including, without limitation, a merger or sale of all or substantially all of the Company's assets to which Brightlife relates or other corporate reorganization in which the Company participates, and is thus merged with or acquired by a third-party entity (a "Successor"), the Company hereby reserves the right to transfer the information we have collected from the users of Brightlife to such Successor</p>
                                <p>In addition, in the event of the Company's bankruptcy, reorganization, receivership, or assignment for the benefit of creditors, or the application or laws or equitable principles affecting creditor's rights generally, the Company may not be able to control how your information is transferred, used, or treated and reserves the right to transfer the information we have collected from the users of Brightlife to non-affiliated third parties in such event.</p>
                            </div>
                        </div>
                        <hr/>
                        <div class="row">
                            <div class="col-md-12">
                                <h4>F. Links to External Websites</h4>
                                <p>Brightlife may contain links to other websites or resources over which the Company does not have any control. Such links do not constitute an endorsement by the Company of those external websites. You acknowledge that the Company is providing these links to you only as a convenience, and further agree that the Company is not responsible for the content of such external websites. We are not responsible for the protection and privacy of any information which you provide while visiting such external websites and such sites are not governed by this Policy. Your use of any external website is subject to the terms of use and privacy policy located on the linked to external website.</p>
                            </div>
                        </div>
                        <hr/>
                        <div class="row">
                            <div class="col-md-12">
                                <h4>G. Updating, Deleting and Correcting Your Personal Information</h4>
                                <p>You may choose to restrict the collection or use of your personal information in the following ways:</p>
                                <p>You can review, correct and delete your personal information by logging into Brightlife. You must promptly update your personal information if it changes or is inaccurate. Typically, we will not manually alter your personal information because it is very difficult to verify your identity remotely. Nonetheless, upon your request we will close your Account and remove your personal information from view as soon as reasonably possible based on your Account activity and in accordance with applicable law. We do retain information from closed Accounts in order to comply with the law, prevent fraud, collect any fees owed, resolve disputes, troubleshoot problems, assist with any investigations of any Registered User, enforce our Terms of Use, and take any other actions otherwise permitted by law that we deem necessary in our sole and absolute discretion. You should understand, however, that once you publicly post any Submitted Content etc., or any other information onto Brightlife, you may not be able to change or remove it. Once we have deleted or removed your Account, you agree that the Company shall not be responsible for any personal information that was not included within your deleted and/or removed Account that remains on Brightlife.</p>
                            </div>
                        </div>
                        <hr/>
                        <div class="row">
                            <div class="col-md-12">
                                <h4>H. What Choices Do I Have Regarding Use of My Personal Information?</h4>
                                <p>We may send periodic promotional or informational emails to you. You may opt-out of such communications by following the opt-out instructions contained in the email. Please note that it may take up to 10 business days for us to process opt-out requests. If you opt-out of receiving emails about recommendations or other information we think may interest you, we may still send you emails about your Account or any services you have requested or received from us.</p>
                            </div>
                        </div>
                        <hr/>
                        <div class="row">
                            <div class="col-md-12">
                                <h4>I. User Generated Content</h4>
                                <p>We invite you to post content on Brightlife, including your comments, feedback, pictures, and any other information that you would like to be available on Brightlife. If you post content to Brightlife, all of the information that you post will be available to all visitors to Brightlife, unless the same has been done anonymously in accordance with Brightlife features. If you post your own content on Brightlife, your posting may become public and the Company cannot prevent such information from being used in a manner that may violate this Policy, the law, or your privacy.</p>
                            </div>
                        </div>
                        <hr/>
                        <div class="row">
                            <div class="col-md-12">                                
                                <h4>J. Privacy Policy Updates</h4>
                                <p>THIS POLICY IS CURRENT AS OF THE EFFECTIVE DATE SET FORTH ABOVE. THE COMPANY MAY, IN ITS SOLE AND ABSOLUTE DISCRETION, CHANGE THIS POLICY FROM TIME TO TIME BY UPDATING THIS DOCUMENT. THE COMPANY WILL POST ITS UPDATED POLICY ON BRIGHTLIFE ON THIS PAGE. THE COMPANY ENCOURAGES YOU TO REVIEW THIS POLICY REGULARLY FOR ANY CHANGES. YOUR CONTINUED USE OF BRIGHTLIFE AND/OR CONTINUED PROVISION OF PERSONAL INFORMATION TO US WILL BE SUBJECT TO THE TERMS OF THE THEN-CURRENT POLICY.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

PrivacyPolicy.propTypes = {
    
};

export default PrivacyPolicy;