import React, { useEffect, useState } from 'react';
import { Link, Outlet, useParams  } from 'react-router-dom';
import { Table } from '../Common/Table';
import { Layout } from '../Layout/Layout';
import axios from 'axios';
import Loader from '../Loader/Loader';
import { CSVLink } from "react-csv";

export const Wallet = () => {
    const { userId } = useParams();
    const [list,setList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [csvData, setCsvData] = useState([]);
    
    useEffect(() => {
        getList();
    }, []);
    const getList = async() => {
        setLoading(true)
        await axios.get('/admin/providers/providerHistory?userId='+userId)
        .then(function (response) {
        setLoading(false)
        let result = response.data;
            if(result.success){
                let csvData = []
                result.data.map( (walletData) => {
                    csvData.push({
                        transactionId: walletData.transactionId.replace("debit", "credit"), 
                        providerAmount: walletData.providerAmount, 
                        type: walletData.type === 'Debit' ? "Credit" : "-",
                        transactionDate: walletData.transactionDate,
                        providerDescription: walletData.providerDescription,
                    })
                })
                // console.log("csvData=",csvData)
                
                setList(csvData)
                setCsvData(csvData)

            }
        })
    }
    
    const columns = [
        {
            name: 'Transaction Id',
            selector: row => row.transactionId,
            sortable: true,
        },
        {
            name: 'Transaction Amount',
            selector: row => row.providerAmount,
            sortable: true,
        },
        {
            name: 'Transaction Type',
            selector: row => row.type,
        },
        {
            name: 'Transaction Date',
            selector: row => row.transactionDate,
        },
        {
            name: 'Transaction Description',
            selector: row => row.providerDescription,
        }       
    ];

    return (
        <>
        {loading ? <Loader/> : ''};
            <Layout>  
                <div className="row">
                    <div className="col-6">
                        <h1 className="display-4 ft-dark exprtl-page-title">Wallet Transaction List</h1>
                    </div>
                    {/* <div className="col-6 text-right">
                        <Link to='/providers/add' className="r-btn r-btn-primary mt-1">
                            <i className="fa fa-plus"></i><span>Export Data</span>
                        </Link>
                    </div> */}
                    <div className="col-6 text-right">
                        <CSVLink filename={"Wallet_transactions.csv"} data={csvData} className="r-btn r-btn-primary mt-1" >
                            <i className="fa fa-download"></i><span>Export Data</span>
                        </CSVLink>
                    </div>
                </div>
                <div className="app-white-container">
                    <div className="row">
                        <Table columns={columns} data={list}/>
                    </div>
                </div>
            </Layout>     
        </>
    )
}
export default Wallet;

Wallet.propTypes = {
};