
import { Navigate, Route, Routes } from "react-router-dom";
import './App.css';
import PublicRoutes from "./components/Routes/PublicRoutes";
import ProtectedRoutes from "./components/Routes/ProtectedRoutes";
import Login from './components/Login/Login';
import ForgotPassword from "./components/Login/ForgotPassword";
import Providers from "./components/Providers/List";
import AddEditProvider from "./components/Providers/Add";
import ViewProvider from "./components/Providers/View";
import Clients from "./components/Clients/List";
import Chats from "./components/Chats/List";
import Bookings from "./components/Bookings/List";
import Categories from "./components/Category/List";
import Seeks from "./components/Seek/List";
import ChangePassword from "./components/Profile/ChangePassword"
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import EditClient from "./components/Clients/Edit"
import "./intercepter";
import { createContext, useState } from "react";
import Discounts from "./components/Discount/List";
import DiscountCoupons from "./components/DiscountCoupon/List";
import Offers from "./components/Offers/Add";
import ProviderWallet from "./components/Providers/Wallet"

// const ToggleContext = createContext(null);

function App() {
  // const [toggle, setToggle] = useState(false);

  return (
    <Routes>
      <Route path="login" element={<PublicRoutes />}>
        <Route path="/login" element={<Login />} />
      </Route>
      <Route path="forgot_password" element={<PublicRoutes />}>
        <Route path="/forgot_password" element={<ForgotPassword />} />
      </Route>
      <Route path="privacy_policy" element={<PublicRoutes />}>
        <Route path="/privacy_policy" element={<PrivacyPolicy />} />
      </Route>
      {/* <ToggleContext.Provider value={toggle}> */}
        <Route path="/" element={<ProtectedRoutes />}>
          <Route path="/" element={<Navigate replace to="providers" />} />
          <Route path="/providers" element={<Providers />} />
          <Route path="/providers/add" element={<AddEditProvider />} />
          <Route path="/providers/view/:userId" element={<ViewProvider />} />                
          <Route path="/providers/edit/:userId" element={<AddEditProvider />} />
          <Route path="/providers/wallet/:userId" element={<ProviderWallet />} />
          <Route path="/clients" element={<Clients />} />
          <Route path="/clients/edit/:userId" element={<EditClient />} />
          <Route path="/chats" element={<Chats />} />        
          <Route path="/bookings" element={<Bookings />} />                
          <Route path="/categories" element={<Categories />} /> 
          <Route path="/seeks" element={<Seeks />} />
          <Route path="/change_password" element={<ChangePassword />} />
          <Route path="/discounts" element={<Discounts />} />
          <Route path="/discount_coupons" element={<DiscountCoupons />} />
          <Route path="/offers" element={<Offers />} />
        </Route>
      {/* </ToggleContext.Provider> */}
    </Routes>
  );
}

export default App;
