import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { Table } from '../Common/Table';
import Swal from 'sweetalert2';
import axios from 'axios';
import AddSessionsPlans from './AddSessionsPlans';

export const SessionsPlans = ({userId, ...rest}) => {
    const [sessionsPlan, setSessionsPlan] = useState([]);
    const [show, setShow] = useState(false);
    const [popupType, setPopupType] = useState('Add');
    const [editId, setEditId] = useState(0);
    const handleClose = () => setShow(false);

    const handleAdd = () => {
        setEditId(0);
        setPopupType('Add');
        setShow(true);
    }

    const handleEdit = (id) => {
        setEditId(id);
        setPopupType('Edit');
        setShow(true);
    }

    useEffect(() => {
        getList();
    }, []);

    const getList = async() => {
        await axios.get('/admin/providers/sessionsPlans?userId='+userId)
        .then(function (response) {
            let result = response.data;
            if(result.success){
                setSessionsPlan(result.data)
            }
        })
    }

    const handleDelete = (id) => {
        Swal.fire({
            title: 'Are you sure you want to delete?',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`/admin/providers/sessionsPlans/delete/${id}`).then((res) =>{
                    if(res.data.success){                           
                        Swal.fire(res.data.message, '', 'success').then(result => {
                            getList();
                        });                        
                    } 
                }).catch((error) => {    
                    Swal.fire(error.message, '', 'info')           
                });
            } else if (result.isDenied) {
              Swal.fire('Cancelled!', '', 'info')
            }
        })        
    }

    const columns = [
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'No Of Sessions',
            selector: row => row.noOfSessions,
            sortable: true,
        },
        {
            name: 'Price',
            selector: row => row.price,
            sortable: true,
        },
        {
            name: 'Description',
            selector: row => row.description,
            sortable: true,
        },
        {
            name: 'Action',
            button: true,
            cell: row => (
                <div>
                    <a style={{color: '#fff'}} className="icon icon-sm icon-secondary ml-1" title="Edit Session" onClick={() => handleEdit(row.id)}>
                        <i className="fa fa-pencil-alt"></i>
                    </a>
                    <a style={{color: '#fff'}} className="icon icon-sm icon-primary ml-1" title="Delete Sessions" onClick={e => handleDelete(row.id)}>
                        <i className="fa fa-times"></i>
                    </a>
                </div>
            ),
        }
    ];
    return (
        <>
        <div className="block transparent tab-pane fade" id="r-pills-sessions" role="tabpanel" aria-labelledby="r-pills-sessions-tab">
            <div className="header">
                <h4 className="main-title ft-acent">
                    Sessions
                    <a className="r-btn r-btn-primary invert float-right" onClick={()=>handleAdd()}><i className='fa fa-plus'></i> Add</a>
                </h4>
            </div>
            <div className="content">
                <Table columns={columns} data={sessionsPlan}/>
            </div>
        </div>
        <AddSessionsPlans type={popupType} userId={userId} show={show} setShow={setShow} handleClose={handleClose} getList={getList} editId={editId} setEditId={setEditId}/>
        </>
    );
}

export default SessionsPlans;

SessionsPlans.propTypes = {
    // columns: PropTypes.array.isRequired
};