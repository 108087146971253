import React, { useEffect, useState } from "react";
import { Layout } from "../Layout/Layout";
import SendbirdApp from "@sendbird/uikit-react/App";
import "@sendbird/uikit-react/dist/index.css";
import axios from "axios";
import Loader from '../Loader/Loader';

export default function List() {
  const YOUR_APP_ID = process.env.REACT_APP_SENDBIRD_APP_ID;
  const [userId, setUserId] = useState('0');
  const [users, setUsers] = useState([]);
  const [showChat, setShowChat] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getUsersList();
  }, []);

  const getUsersList = async () => {
        setLoading(true)
      await axios
      .get("/api/users?role=Admin")
      .then(function (response) {
        setLoading(false)
        let result = response.data;
        if (result.success) {
          setUsers(result.data);
        }
      });
  };

  const handleChatOpen = (id) => {
    setUserId(id);
    setShowChat(true);
  }
  
  return (
    <>
    {loading ? <Loader/> : ''};
      <Layout>
        <div className="row">
          <div className="col-6">
            <h1 className="display-4 ft-dark exprtl-page-title">Chats</h1>
          </div>
          <div className="col-6 text-right">
            {
              showChat === false ? '' :
              <button className="r-btn r-btn-primary mt-1" onClick={() => setShowChat(false)}>
                <i className="fa fa-arrow-left"></i><span> Back to List</span>
              </button>
            }
            
        </div>
        </div>
        {
          showChat === false ?
          <div className="row">
            <div className="col-md-4 col-lg-4 col-sm-12">
              <div className="chat-card">
                <div className="card experttal-chat-app">
                  <div id="plist" className="people-list">
                    <div className="chat-header">
                      <div className="row">
                        <h3 className="display-3 ft-dark exprtl-page-title">
                          Users List
                        </h3>
                      </div>
                    </div>
                    <ul className="list-unstyled chat-list ">
                      {
                        users.length > 0 ? 
                          users.map(user => 
                            (<li className="clearfix" onClick={() => { handleChatOpen(`${user.id}`) }}>
                              <img
                                className="rounded-circle width40"
                                src={user.image}
                                alt=""
                              />
                              <div className="about">
                                <div className="name">{user.name}</div>
                                <div className="status">{user.role}</div>
                              </div>
                            </li>)
                          )                        
                          : ''
                      }
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          :
          <div className="App">
            <SendbirdApp
              appId={YOUR_APP_ID} // Specify your Sendbird application ID.
              userId={userId}
            >
              {/* <ChannelList />
                          <Channel /> */}
            </SendbirdApp>
          </div>
        }
        
        
        
      </Layout>
    </>
  );
}
