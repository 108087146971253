import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";
import S3 from "react-aws-s3";
window.Buffer = window.Buffer || require("buffer").Buffer;

export const Add = ({
  type,
  show,
  setShow,
  handleClose,
  getList,
  editId,
  setEditId,
  ...rest
}) => {
  const [form, setForm] = useState({
    id: editId,
    name: "",
    image: "",
  });

  useEffect(() => {
    if (editId > 0) {
      axios.get(`api/singleCategory?id=${editId}`).then((res) => {
        console.log(res);
        if (res.data.success) {
          setForm({
            ...form,
            id: editId,
            name: res.data.data.name,
            image: res.data.data.image,
          });
        }
      });
    } else {
      setForm({ ...form, id: editId, name: "" });
    }
  }, [editId]);

  const handleImageChange = (e) => {
    let file = e.target.files[0];
    const config = {
      bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
      // dirName: process.env.REACT_APP_DIR_NAME /* optional */,
      region: process.env.REACT_APP_S3_REGION,
      accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
    };
    const ReactS3Client = new S3(config);
    const newFileName =
      "C" + Math.floor(Math.random() * (99999999 - 10000000 + 1) + 10000000);
    ReactS3Client.uploadFile(file, newFileName)
      .then((data) => {
        if (data.status === 204) {
          setForm({ ...form, image: data.location });
        } else {
          console.log("error");
        }
      })
      .catch((err) => console.error(err));
  };

  const handleSave = () => {
    if (editId > 0) {
      axios
        .put(`api/categories/update`, form)
        .then((res) => {
          if (res.data.success) {
            setShow(false);
            Swal.fire(res.data.message, "", "success").then((result) => {
              getList();
            });
          } else {
            Swal.fire(res.data.message, "", "error");
          }
        })
        .catch((error) => {
          // console.log(error.response.data)
          Swal.fire(error.response.data.message, "", "error");
        });
    } else {
      axios
        .post(`api/categories/create`, form)
        .then((res) => {
          if (res.data.success) {
            setShow(false);
            Swal.fire(res.data.message, "", "success").then((result) => {
              getList();
              setForm({ name: "" });
            });
          } else {
            Swal.fire(res.data.message, "", "error");
          }
        })
        .catch((error) => {
          // console.log(error.response.data)
          Swal.fire(error.response.data.message, "", "error");
        });
    }
  };
  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="modal-md"
      style={{ zIndex: 9999 }}
    >
      <Modal.Header>
        <h5 className="modal-title">{type} Category</h5>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={handleClose}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <label htmlFor="">Name</label>
          <input
            name="name"
            type="text"
            className="form-control"
            value={form.name}
            onChange={(e) => setForm({ ...form, name: e.target.value })}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="">Category Image</label>

          <img
            src={form.image}
            width={50}
            height={50}
            style={{ display: type == "Add" ? "none" : "block" }}
          />

          <input
            name="image"
            type="file"
            className="form-control"
            accept=".png, .jpg, .jpeg"
            onChange={handleImageChange}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="r-btn r-btn-base"
          onClick={handleClose}
        >
          Close
        </button>
        <button
          type="button"
          onClick={handleSave}
          className="r-btn r-btn-primary"
        >
          {type == "Add" ? "Add" : "Update"}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default Add;
Add.propTypes = {
  type: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};
