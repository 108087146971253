import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from 'sweetalert2';
import S3 from "react-aws-s3";
window.Buffer = window.Buffer || require("buffer").Buffer;

export const ImageVideo = ({ userId, ...rest }) => {
  const [attachments, setAttachments] = useState([]);
  const config = {
    bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
    // dirName: process.env.REACT_APP_DIR_NAME /* optional */,
    region: process.env.REACT_APP_S3_REGION,
    accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
  };

  useEffect(() => {
    getList();
  }, []);

  const getList = async () => {
    await axios
      .get("/admin/providers/attachments?userId=" + userId)
      .then(function (response) {
        let result = response.data;
        if (result.success) {
          setAttachments(result.data);
        }
      });
  };

  const handleFilesChange = (e) => {
    let files = e.target.files;
    
    const ReactS3Client = new S3(config);

    // Object.values(files).forEach((file, index) => {
    //   if (file.type == "image/png") {
    //     const reader = new FileReader()
    //     reader.readAsDataURL(file)
        
    //     if(file.size <= 1048576){
    //       console.log('b');
    //       reader.onload = () => {
    //         setAttachments([...attachments, {
    //           id: 'u'+index,
    //           path: reader.result,
    //           uploading: true,
    //           isSizeLarge: false
    //         }])
    //       }
    //     }
    //     else{
    //       reader.onload = () => {
    //         setAttachments([...attachments, {
    //           id: 'u'+index,
    //           path: reader.result,
    //           uploading: true,
    //           isSizeLarge: true
    //         }])
    //       }
    //     }
    //   }
    //   else{
        
    //   }
    // })

    Object.values(files).forEach((file, index) => {
      if (file.size <= 1048576 && (file.type == "image/png" || file.type == "image/jpg" || file.type == "image/jpeg")) {
        const newFileName =
          "A" +
          Math.floor(Math.random() * (99999999 - 10000000 + 1) + 10000000);
        ReactS3Client.uploadFile(file, newFileName)
          .then((data) => {
            if (data.status === 204) {
              // console.log(data);
              axios.post("/admin/providers/attachments", {
                name: newFileName,
                userId: userId,
                type: "Image",
                path: data.location,
              });
              getList();
              // setAttachments(attachments.map(attachment => {
              //   if(attachment.id == 'u'+index){
              //     attachment.uploading = false
              //   }
              //   return attachment;
              // }))
            } else {
              console.log("error");
            }
          })
          .catch((err) => console.error(err));
      }
      else{
        Swal.fire('Size too big! or file format not supported')
      }
    });
  };

  const handleDelete = (id, name) => {
    Swal.fire({
      title: 'Are you sure you want to delete?',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        // const ReactS3Client = new S3(config); 
        // ReactS3Client
        //   .deleteFile(name)
        //   .then(response => console.log(response))
        //   .catch(err => console.error(err))
          axios.delete(`/api/users/attachments/${id}`).then((res) =>{
              if(res.data.success){                             
                  Swal.fire('Deleted!', '', 'success').then(result => {
                    getList();
                  })                  
              } 
          }).catch((error) => {
              console.log(error);                     
              Swal.fire(error.message, '', 'info')           
          });
      } else if (result.isDenied) {
        Swal.fire('Cancelled!', '', 'info')
      }
  })
  }

  return (
    <>
      <div
        className="tab-pane fade block"
        id="r-pills-images-videos"
        role="tabpanel"
        aria-labelledby="r-pills-images-videos-tab"
      >
        <div className="header">
          <h4 className="main-title ft-acent">
            Images & Videos
            <div className="float-right upload-btn-wrapper">
              <button className="rj-btn">Upload Files</button>
              <input type="file" name="attachments[]" onChange={handleFilesChange} multiple="multiple"/>
            </div>
            {/* <a className="r-btn r-btn-primary invert float-right">Upload</a>
            <input
              type="file"
              className="r-btn r-btn-primary invert float-right"
              name="attachments[]"
              onChange={handleFilesChange}
              multiple="multiple"
            /> */}
          </h4>
        </div>
        <div className="row">
          <div className="col-md-12">            
            <p className="float-right"><b>*</b> .png, .jpg and .jpeg only supported with size less than 1 MB </p>
          </div>
        </div>
        <div className="content">
          <ul className="certificate-wrap">
            {attachments.length > 0
              ? attachments.map((attachment) => (
                  <li className="certificate-item">
                    <div
                      className="certificate-img"
                      style={{
                        backgroundImage: "url('" + attachment.path + "')",
                      }}
                    >
                      <ui className="text-truncate">
                        <span className="float-right icon icon-sm ft-danger bg-light m-1 cursor-pointer" onClick={() => handleDelete(attachment.id, attachment.name)}>
                          <i className="fa fa-trash"></i>
                        </span>
                      </ui>
                      {
                        attachment.uploading && attachment.uploading === true ? 
                        <div class="r-progress">
                          <div class="r-progress-value"></div>
                        </div>
                        : ''
                      }
                      
                    </div>
                  </li>
                ))
              : ""}
          </ul>
        </div>
      </div>
    </>
  );
};

export default ImageVideo;

ImageVideo.propTypes = {};
