import React  from 'react';
import PropTypes from "prop-types";
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';

export const Info = ({show, setShow, handleClose, bookingInfo,getList,loading,setLoading, ...rest}) => { 
    const handleSessionComplete = (e, id) => {
        setLoading(true);
        var status = 'Accepted';
        if(e.target.checked === true) {
            status = 'Completed'
        }
        else {
            status = 'Accepted'
        }
        axios.put(`api/bookings/sessionComplete?id=${id}`, {status}).then(res => { 
            setLoading(false);
            getList();
        })
        .catch(err => {
        })
    }
    return (
        <Modal show={show} onHide={handleClose} dialogClassName="modal-md" style={{zIndex: 9999}}>
            <Modal.Header>
                <h5 className="modal-title">Booking Info</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </Modal.Header>
            <Modal.Body>
                <div className="form-group">                    
                    <div className="row">
                        <div className='col-md-6'>
                            <label htmlFor="">Name: </label>
                            <span> {bookingInfo.name === '' || bookingInfo.name === null ?  " NA" : bookingInfo.name}</span>
                        </div>
                        <div className='col-md-6'>
                            <label htmlFor="">Email: </label>
                            <span> {bookingInfo.email === '' || bookingInfo.email === null ?  " NA" : bookingInfo.email}</span>
                        </div>
                    </div>

                    <div className="row">
                        <div className='col-md-6'>
                            <label htmlFor="">Mobile: </label>
                            <span> {bookingInfo.mobile === '' || bookingInfo.mobile === null ?  " NA" : bookingInfo.mobile}</span>
                        </div>
                        <div className='col-md-6'>
                            <label htmlFor="">Age: </label>
                            <span> {bookingInfo.age === '' || bookingInfo.age === null ? 0 : bookingInfo.age}</span>
                        </div>
                    </div>

                    <div className="row">
                        <div className='col-md-6'>
                            <label htmlFor="">Gender: </label>
                            <span> {bookingInfo.gender === '' || bookingInfo.gender === null ? " NA" : bookingInfo.gender}</span>
                        </div>
                        <div className='col-md-6'>
                            <label htmlFor="">City: </label>
                            <span> {bookingInfo.city === '' || bookingInfo.city === null ?  " NA" : bookingInfo.city}</span>
                        </div>
                    </div>

                    <div className="row">
                        <div className='col-md-6'>
                            <label htmlFor="">Country: </label>
                            <span> {bookingInfo.country === '' || bookingInfo.country === null ?  " NA" : bookingInfo.country}</span>
                        </div>
                        <div className='col-md-6'>
                            <label htmlFor="">Occupation: </label>
                            <span> {bookingInfo.occupation === '' || bookingInfo.occupation === null ?  " NA" : bookingInfo.occupation}</span>
                        </div>
                    </div>
                    <label htmlFor="">Goals: </label>
                    <span> {bookingInfo.goals === '' || bookingInfo.goals === null ?  " NA" : bookingInfo.goals}</span>
                    
                    <hr/>
                    <h6  style={{fontWeight:"500"}}>Sessions </h6>
                    <hr/>
                   {
                    bookingInfo.user_session_plan !== null  && bookingInfo.type === 'Session'?
                        <>
                            <table>
                                <thead>
                                    <tr style={{fontWeight:"500"}}>
                                        <td width="40%">Name</td>
                                        <td width="20%">Total Sessions</td>
                                        <td width="20%">Status</td>
                                        <td width="20%">Mark Complete</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{bookingInfo.user_session_plan.name}</td>
                                        <td>{bookingInfo.user_session_plan.noOfSessions}</td>
                                        <td>{bookingInfo.status}</td>
                                        <td>                                    
                                            <div>
                                                <input className="tgl tgl-light" name="status"  id={"chk_"+bookingInfo.id} type="checkbox"  onChange={(e) => handleSessionComplete(e, bookingInfo.id)}  defaultChecked={bookingInfo.status !== 'Completed' ? false : true}/>
                                                <label title="Active" className="tgl-btn mt-1" htmlFor={"chk_"+bookingInfo.id} ></label>
                                            </div>
                                        </td>

                                    </tr>
                                </tbody>
                            </table>
                        </>
                    :
                    'Session Details not Found'
                   }

                </div>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="r-btn r-btn-base" onClick={handleClose}>Close</button>
            </Modal.Footer>
        </Modal>
    );
}
export default Info;
Info.propTypes = {
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired    
};