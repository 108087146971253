import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import axios from "axios";

const validateForm = data => {
  const errors = {};  
  if (!data.email) {
      errors.email = 'Please Enter Email';
  } 
  return errors;
};

export default function ForgotPassword() {
    const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    var head = document.head;
    var link = document.createElement("link");

    link.type = "text/css";
    link.rel = "stylesheet";
    link.href = "./assets/css/login_main.css";
    head.appendChild(link);
    setIsLoading(false);
    return () => { head.removeChild(link); }

  }, []);

  const [isError, setIsError] = useState(false);

  const [changePasswordStatus, setChangePasswordStatus] = useState('');
  const [changePasswordMsg, setChangePasswordMsg] = useState('');
//   const [isEmailExist, setIsEmailExist] = useState(false);

  const formik = useFormik({
    initialValues:{
        email: '',
    },
    validate: validateForm,
    onSubmit: values => {
        forgotPassword(values);            
    }
  });

  const forgotPassword = (data) => {
    console.log("jjjjjjjjj",data);
    axios.post(`/admin/forgotpassword`, data).then((result) =>{
        console.log(result.data);
        setChangePasswordStatus(result.data.success);
        setChangePasswordMsg(result.data.message);
    }).catch((error) => {
        console.log('error===',error.message);        
    });

  }

  const checkEmail = (e) => {
    console.log("hjhhhhhhhhh",e.target.value)
    if(e.target.value != ''){
        axios.get(`/admin/checkEmail?email=${e.target.value}`).then((res) =>{
            console.log(res.data.success)
            if(res.data.success){
                // setIsEmailExist(false);
                setIsError(true);
            }
            else{
                // setIsEmailExist(true);
                setIsError(false);
            }
        }).catch((error) => {
            console.log('error===',error.message);        
        });
    }        
}
  const handleCheckCurrentEmail = (email) => {
    console.log('email',email);
    if(email.length > 5){
      return fetch(process.env.REACT_APP_API_URL + 'checkvalidateemail', {
          method: 'POST',
          headers: {
              'accept': '*/*',
              'Content-Type': 'application/json',
              'token': localStorage.getItem('token')
          },
          body: JSON.stringify({email})
      }).then(email => email.json()).then(result => {
          console.log(result);
          if (result.success) {
            setIsError(false);
          }
          else{
            setIsError(true);
          }
      });
    }
}

  return (
    <>
    {
        isLoading === false ? <>
        <div className="top-header">
            <div className="container">
                <div className="row">
                    <div className="col-6 mt-2">
                        <div className="home-logo">
                        <Link to="/login">
                            <img src="./assets/img/brightlife-logo.png" alt="BrightLife" style={{width: '38%'}}/>
                        </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <section className="login-block">
        <div className="container">
                <div className="signup-container">
                    <div className="row">
                        <div className="col-md-8 banner-sec">
                            <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                                <ol className="carousel-indicators">
                                    <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                                    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>                        
                                    <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>                        
                                    <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>                     
                                </ol>
                                <div className="carousel-inner" role="listbox">
                                    <div className="carousel-item active">
                                        <img className="d-block img-fluid" src="./assets/img/slide-1.jpg" alt=""/>
                                        {/* <div className="carousel-caption d-none d-md-block">
                                            <div className="banner-text">
                                                <h2>We Are ExpertTal</h2>
                                                <p>Hire Developers According to Your Requirements</p>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="carousel-item">
                                        <img className="d-block img-fluid" src="./assets/img/slide-2.jpg" alt=""/>
                                        {/* <div className="carousel-caption d-none d-md-block">
                                            <div className="banner-text">
                                                <h2>We Are ExpertTal</h2>
                                                <p>Hire from the fastest growing platform for IT Freelancers / Resources from India.</p>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="carousel-item">
                                        <img className="d-block img-fluid" src="./assets/img/slide-3.jpg" alt=""/>
                                        {/* <div className="carousel-caption d-none d-md-block">
                                            <div className="banner-text">
                                                <h2>We Are ExpertTal</h2>
                                                <p>Hire the Best remote IT Professional who can work for Startups / Enterprises / Fortune 500 companies globally.</p>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="carousel-item">
                                        <img className="d-block img-fluid" src="./assets/img/slide-4.jpg" alt=""/>
                                        {/* <div className="carousel-caption d-none d-md-block">
                                            <div className="banner-text">
                                                <h2>We Are ExpertTal</h2>
                                                <p>Professional who can work for Startups/ Enterprises / Fortune 500 companies globally.</p>
                                            </div>
                                        </div> */}
                                    </div>                          
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 login-sec">
                            <div className="logo">
                                <Link to="/login">
                                    <img src="./assets/img/brightlife-logo.png" alt="BrightLife"/>
                                </Link>
                            </div>
                            <Link to="/login" className="goback">                       
                                <img className="goback-home" src="./assets/img/goBack.png" alt="Home" data-toggle="tooltip" data-placement="right" title="Go Back to Home"/>
                            </Link>                    
                            <div className="auth-item form mt-5">
                                <h4 className="heading ft-primary fw-600 text-center ft-uppercase">FORGOT PASSWORD</h4>
                                <div className={changePasswordStatus  ? 'alert alert-success text-center removeNotification' : 'alert alert-danger text-center removeNotification'} style={{ display: changePasswordStatus !== '' ? 'block' : 'none'}}>
                                    {changePasswordStatus !== '' ? changePasswordMsg : ''}
                                 {/* <a onClick = {removeNotification} class="close" data-dismiss="alert" aria-label="close" title="close">×</a> */}
                                </div>

                                {/* <p className="ft-xss">Lorem, ipsum dolor sit amet dolor sit.</p> */}
                                <form onSubmit={formik.handleSubmit} >
                                    <div className="form-group adon-group mt-4">
                                        <span className="icon icon-md ft-dark-500"><i className="fa fa-envelope"></i></span>
                                        <input type="text" className="form-control" placeholder="Email"  name="email" onBlur={formik.handleChange} onChange={formik.handleChange} onKeyUp={checkEmail} defaultValue={formik.values.email}/>
                                    </div> 
                                        {isError && (<p className="text-danger">Email does not Exist!</p>)}
                                        {formik.touched.email && formik.errors.email ? <div className="text-danger">{formik.errors.email}</div> : null}                     
                                    <div className="form-group">
                                        <button type='submit' style={{color:'white'}} className="r-btn r-btn-primary btn-block mt-2 ft-uppercase"  disabled={isError ? true : false}>
                                            <i className="fa fa-paper-plane" aria-hidden="true"></i> Send Password Reset Link
                                        </button>

                                        {/* <a href="#" className="r-btn r-btn-primary btn-block mt-2 ft-uppercase">
                                        <i className="fa fa-paper-plane" aria-hidden="true"></i> Send Password Reset Link
                                        </a> */}
                                    </div>
                                </form>
                                <hr/>
                                <div className="create-account">
                                    <Link to="/login" className="r-btn r-btn-primary invert btn-block mt-2 mb-5 ft-uppercase">
                                    Back To Login
                                    </Link>                        
                                <p className="ft-xss mt-4">© 2022 BrightLife. All Rights Reserved.</p>
                            </div>
                        </div>
                    </div>                
                </div>
        </div>
        </div>
        </section>
        </> : ''
    }
      
    </>
  );
}