import React, { useEffect, useRef, useState } from 'react';
import { Layout } from '../Layout/Layout';
import { useNavigate, useParams } from 'react-router-dom';
import axios from "axios";
import Attachments from '../Popup/Attachments';
import Swal from 'sweetalert2';
import { MultiSelect } from "react-multi-select-component";
import { useFormik } from 'formik';
import { Award } from './Award';
import { Education } from './Education';
import S3 from 'react-aws-s3';
import ImageVideo from './ImageVideo';
import { SessionsPlans } from './SessionsPlans';

window.Buffer = window.Buffer || require("buffer").Buffer; 
 
const validateForm = data => {
    const errors = {};  
    if (!data.name) {
        errors.name = 'Please enter Name';
    }
    if (!data.email) {
        errors.email = 'Please enter Email ID';
    }
    if (!data.phone) {
        errors.phone = 'Please enter Phone Number';
    }
    if (!data.gender) {
        errors.gender = 'Please select Gender';
    }
    if (!data.dateOfBirth) {
        errors.dateOfBirth = 'Please select Date of Birth';
    }    
    if (!data.experience) {
        errors.experience = 'Please enter Experience';
    }
    if (!data.chatRate) {
        errors.chatRate = 'Please enter Chat Rate';
    }
    if (!data.commisionRate) {
        errors.commisionRate = 'Please enter Commision Rate';
    }
    if (!data.callRate) {
        errors.callRate = 'Please enter Call Rate';
    }
    if (!data.sessionRate) {
        errors.sessionRate = 'Please enter Session Rate';
    }
    if (!data.rating) {
        errors.rating = 'Please enter Rating';
    }
    if (data.categories.length == 0 ) {
        errors.categories = 'Please select at least Category';
    }
    if(!data.shortDescription){
        errors.shortDescription = 'Please fill short description';
    }
    return errors;
};
 
export default function Add() {
    const imageInput = useRef();
    const navigate = useNavigate();
    const { userId } = useParams(); 
    const [categories,setCategories] = useState([]);
    const [selected, setSelected] = useState([]);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [form, setForm] = useState({
        name: '',
        gender: '',
        dateOfBirth: '',
        email: '',
        phone: '',
        experience:'',
        commisionRate: '',
        chatRate: '',
        callRate: '',
        sessionRate: '',
        shortDescription:'',
        image:'',
        role: 'Provider',
        rating: '',
        categories: [],
        specializations: ''
    });
    const [isEmailExist, setIsEmailExist] = useState(false);
    const [isPhoneExist, setIsPhoneExist] = useState(false);
    const [isToggle, setIsToggle] = useState(false);
 
    useEffect(() => {
        getCategoriesList();
        if(userId !== undefined){
            axios.get(`/api/user?id=${userId}`).then((res) =>{         
                if(res.data.success){
                    setformikValues(res.data.data);
                }
            }).catch((error) => {
                console.log(error);
            });          
        }
        console.log(formik.values.image)
    }, []);

    const setformikValues = (result) => {
        console.log(result)
        formik.values.name = result.name;
        formik.values.email = result.email;
        formik.values.phone = result.phone;
        formik.values.image = result.image;
        formik.values.gender = result.user_detail.gender;
        formik.values.dateOfBirth = result.user_detail.dateOfBirth;
        formik.values.experience = result.user_detail.experience;
        formik.values.chatRate = result.user_detail.chatRate;
        formik.values.commisionRate = result.user_detail.commisionRate;
        formik.values.callRate = result.user_detail.callRate;
        formik.values.sessionRate = result.user_detail.sessionRate;
        formik.values.rating = result.user_detail.rating;
        formik.values.shortDescription = result.user_detail.shortDescription;
        formik.values.categories = result.user_categories.map(category => {
            return category.categoryId
        })
        setSelected(result.user_categories.map(category => {
            return {label: category.category.name, value: category.categoryId}
        }))
        formik.values.specializations = result.user_specializes[0].name;
        setForm(formik.values);
    }
 
    const formik = useFormik({
        initialValues: form,
        validate: validateForm,
        onSubmit: values => {
            handleSubmit(values);            
        }
    });
 
    const getCategoriesList = () => {
        axios.get('/api/categories').then(function (response) {
            let result = response.data;
            if(result.success){
                const options = result.data.map(item => {
                    return {
                        label : item.name,
                        value : item.id
                    }
                });
                setCategories(options);
            }
        })
    }

    const handleImageChange = (e) => {
        let file = e.target.files[0];
        const config = {
            bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
            // dirName: process.env.REACT_APP_DIR_NAME /* optional */,
            region: process.env.REACT_APP_S3_REGION,
            accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
            secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY
        };
        const ReactS3Client = new S3(config);
        const newFileName = 'P'+Math.floor(
            Math.random() * (99999999 - 10000000 + 1) + 10000000
        );
        ReactS3Client.uploadFile(file, newFileName)
            .then(data => {
                if(data.status === 204){
                    formik.values.image = data.location;
                    setForm({...form, image: data.location});
                }
                else{
                    console.log('error')
                }
                
            })
            .catch(err => console.error(err))
    }

    const handleSubmit = (values) => {
        setIsSubmitted(true);
        var opertation = userId ? 'Edit' : 'Add';
        const addUpdateUser = userId ? axios.put(`/admin/providers/${userId}`, values) : axios.post('/admin/providers', values);
        
        addUpdateUser.then((res) =>{
            if(res.data.success){
                Swal.fire(res.data.message, '', 'success').then(result => {
                    if(opertation == 'Add'){
                        navigate("/providers")
                    }
                    else{
                        navigate("/providers/edit/"+userId)
                    }                    
                })                
            }
            else{
                Swal.fire(res.data.message, '', 'info')
            }
        }).catch((error) => {
            Swal.fire(error.message, '', 'info')        
        });
    }

    const checkEmail = (e) => {
        if(e.target.value != ''){
            axios.get(`/admin/checkEmail?email=${e.target.value}&id=${userId}`).then((res) =>{
                if(res.data.success){
                    setIsEmailExist(true);
                }
                else{
                    setIsEmailExist(false)
                }
            }).catch((error) => {
                console.log('error===',error.message);        
            });
        }        
    }

    const checkPhone = (e) => {
        if(e.target.value != ''){
            axios.get(`/admin/checkPhone?phone=${e.target.value}&id=${userId}`).then((res) =>{
                if(res.data.success){
                    setIsPhoneExist(true);
                }
                else{
                    setIsPhoneExist(false)
                }
            }).catch((error) => {
                console.log('error===',error.message);        
            });
        }        
    }

    const handleChangeMultiSelect = (e) => {
        console.log(e);
        setSelected(e);
        const catg = e.map((a) => {return a.value});
        setForm({...form, categories: catg});
        formik.values.categories = catg;
        if(catg.length > 0){
            formik.errors.categories = '';
        }
        else{
            formik.errors.categories = 'Please select at least Category';
        }
        
    }

    const handleTabs = (tabId) => {
        let tab = document.getElementById(tabId);
        let href = tab.getAttribute("href");
        let contentDivIdData = href.split("#");
        let contentDivId = contentDivIdData[1];
        let contentDiv = document.getElementById(contentDivId);
        
        tab.classList.add('active');
        tab.setAttribute('aria-selected', true);
        contentDiv.classList.add('active');
        contentDiv.classList.add('show');

        if(tabId == 'r-pills-education-tab'){
            document.getElementById('r-pills-home-tab').classList.remove('active');
            document.getElementById('r-pills-home-tab').setAttribute('aria-selected', false);
            document.getElementById('r-pills-home').classList.remove('active');
            document.getElementById('r-pills-home').classList.remove('show');
            
            document.getElementById('r-pills-awards-tab').classList.remove('active');
            document.getElementById('r-pills-awards-tab').setAttribute('aria-selected', false);
            document.getElementById('r-pills-awards').classList.remove('active');
            document.getElementById('r-pills-awards').classList.remove('show');

            document.getElementById('r-pills-images-videos-tab').classList.remove('active');
            document.getElementById('r-pills-images-videos-tab').setAttribute('aria-selected', false);
            document.getElementById('r-pills-images-videos').classList.remove('active');
            document.getElementById('r-pills-images-videos').classList.remove('show');
            
            document.getElementById('r-pills-sessions-tab').classList.remove('active');
            document.getElementById('r-pills-sessions-tab').setAttribute('aria-selected', false);
            document.getElementById('r-pills-sessions').classList.remove('active');
            document.getElementById('r-pills-sessions').classList.remove('show');
        }
        else if(tabId == 'r-pills-awards-tab'){
            document.getElementById('r-pills-home-tab').classList.remove('active');
            document.getElementById('r-pills-home-tab').setAttribute('aria-selected', false);
            document.getElementById('r-pills-home').classList.remove('active');
            document.getElementById('r-pills-home').classList.remove('show');
            
            document.getElementById('r-pills-images-videos-tab').classList.remove('active');
            document.getElementById('r-pills-images-videos-tab').setAttribute('aria-selected', false);
            document.getElementById('r-pills-images-videos').classList.remove('active');
            document.getElementById('r-pills-images-videos').classList.remove('show');
            
            document.getElementById('r-pills-education-tab').classList.remove('active');
            document.getElementById('r-pills-education-tab').setAttribute('aria-selected', false);
            document.getElementById('r-pills-education').classList.remove('active');
            document.getElementById('r-pills-education').classList.remove('show');
            
            document.getElementById('r-pills-sessions-tab').classList.remove('active');
            document.getElementById('r-pills-sessions-tab').setAttribute('aria-selected', false);
            document.getElementById('r-pills-sessions').classList.remove('active');
            document.getElementById('r-pills-sessions').classList.remove('show');
        }
        else if(tabId == 'r-pills-images-videos-tab'){
            document.getElementById('r-pills-home-tab').classList.remove('active');
            document.getElementById('r-pills-home-tab').setAttribute('aria-selected', false);
            document.getElementById('r-pills-home').classList.remove('active');
            document.getElementById('r-pills-home').classList.remove('show');

            document.getElementById('r-pills-education-tab').classList.remove('active');
            document.getElementById('r-pills-education-tab').setAttribute('aria-selected', false);
            document.getElementById('r-pills-education').classList.remove('active');
            document.getElementById('r-pills-education').classList.remove('show');            
            
            document.getElementById('r-pills-awards-tab').classList.remove('active');
            document.getElementById('r-pills-awards-tab').setAttribute('aria-selected', false);
            document.getElementById('r-pills-awards').classList.remove('active');
            document.getElementById('r-pills-awards').classList.remove('show');
            
            document.getElementById('r-pills-sessions-tab').classList.remove('active');
            document.getElementById('r-pills-sessions-tab').setAttribute('aria-selected', false);
            document.getElementById('r-pills-sessions').classList.remove('active');
            document.getElementById('r-pills-sessions').classList.remove('show');

        }
        else if(tabId == 'r-pills-home-tab'){
            document.getElementById('r-pills-images-videos-tab').classList.remove('active');
            document.getElementById('r-pills-images-videos-tab').setAttribute('aria-selected', false);
            document.getElementById('r-pills-images-videos').classList.remove('active');
            document.getElementById('r-pills-images-videos').classList.remove('show');

            document.getElementById('r-pills-education-tab').classList.remove('active');
            document.getElementById('r-pills-education-tab').setAttribute('aria-selected', false);
            document.getElementById('r-pills-education').classList.remove('active');
            document.getElementById('r-pills-education').classList.remove('show');            
            
            document.getElementById('r-pills-awards-tab').classList.remove('active');
            document.getElementById('r-pills-awards-tab').setAttribute('aria-selected', false);
            document.getElementById('r-pills-awards').classList.remove('active');
            document.getElementById('r-pills-awards').classList.remove('show');
            
            document.getElementById('r-pills-sessions-tab').classList.remove('active');
            document.getElementById('r-pills-sessions-tab').setAttribute('aria-selected', false);
            document.getElementById('r-pills-sessions').classList.remove('active');
            document.getElementById('r-pills-sessions').classList.remove('show');
        }
        else if(tabId == 'r-pills-sessions-tab'){
            document.getElementById('r-pills-home-tab').classList.remove('active');
            document.getElementById('r-pills-home-tab').setAttribute('aria-selected', false);
            document.getElementById('r-pills-home').classList.remove('active');
            document.getElementById('r-pills-home').classList.remove('show');
             
            document.getElementById('r-pills-images-videos-tab').classList.remove('active');
            document.getElementById('r-pills-images-videos-tab').setAttribute('aria-selected', false);
            document.getElementById('r-pills-images-videos').classList.remove('active');
            document.getElementById('r-pills-images-videos').classList.remove('show');

            document.getElementById('r-pills-education-tab').classList.remove('active');
            document.getElementById('r-pills-education-tab').setAttribute('aria-selected', false);
            document.getElementById('r-pills-education').classList.remove('active');
            document.getElementById('r-pills-education').classList.remove('show');            
            
            document.getElementById('r-pills-awards-tab').classList.remove('active');
            document.getElementById('r-pills-awards-tab').setAttribute('aria-selected', false);
            document.getElementById('r-pills-awards').classList.remove('active');
            document.getElementById('r-pills-awards').classList.remove('show');
        }
      }
 
    return (
    <>
    <Layout>        
        <div className="row">
            <div className="col-6">
                <h1 className="display-4 ft-dark exprtl-page-title">{userId ?'Edit':'Add'} Provider</h1>
            </div>
        </div>
        <div className="app-white-container">        
            <div className="row">
                <div className="col-lg-12">
                    <div className="nav nav-pills" id="r-pills-tab" role="tablist" aria-orientation="vertical">
                        <a className="nav-link active" id="r-pills-home-tab" data-toggle="pill" href="#r-pills-home" role="tab" aria-controls="r-pills-home" aria-selected="true" onClick={() => handleTabs('r-pills-home-tab')}>Personal Info</a>
                        {userId ? <a className="nav-link" id="r-pills-education-tab" data-toggle="pill" href="#r-pills-education" role="tab" aria-controls="r-pills-education" aria-selected="false" onClick={() => handleTabs('r-pills-education-tab')}>Educations</a> :''}
                        {userId ? <a className="nav-link" id="r-pills-awards-tab" data-toggle="pill" href="#r-pills-awards" role="tab" aria-controls="r-pills-awards" aria-selected="false" onClick={() => handleTabs('r-pills-awards-tab')}>Awards</a> :''}
                        {userId ? <a className="nav-link" id="r-pills-images-videos-tab" data-toggle="pill" href="#r-pills-images-videos" role="tab" aria-controls="r-pills-images-videos" aria-selected="false" onClick={() => handleTabs('r-pills-images-videos-tab')}>Images & Videos</a> :''}
                        {userId ? <a className="nav-link" id="r-pills-sessions-tab" data-toggle="pill" href="#r-pills-sessions" role="tab" aria-controls="r-pills-sessions" aria-selected="false" onClick={() => handleTabs('r-pills-sessions-tab')}>Sessions</a> :''}
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="tab-content border" id="r-pills-tabContent">
                        {/* <!-- PERSONAL INFO --> */}
                        <div className="block transparent tab-pane fade show active" id="r-pills-home" role="tabpanel" aria-labelledby="r-pills-home-tab">
                            <div className="header">
                                <h4 className="main-title ft-acent">Personal Information</h4>
                            </div>
                            <form onSubmit={formik.handleSubmit}>
                                <div className="content">
                                    <div className="alert alert-danger alert-white rounded" style={{ display: "none",border:'0px' }}></div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="avatar-upload">
                                                <div className="avatar-edit">
                                                    <input type='file' id="imageUpload" accept=".png, .jpg, .jpeg" name="image" ref={imageInput} onChange={handleImageChange}/>
                                                    <label htmlFor="imageUpload"></label>
                                                </div>
                                                <div className="avatar-preview">
                                                    <div id="imagePreview" style={{backgroundImage: formik.values.image != '' ? "url('"+formik.values.image+"')"  : "url('https://brightlifeapp.s3.us-west-2.amazonaws.com/default-avatar.png')"}}> </div>
                                                </div>                
                                                <h1 className="display-4 ft-dark exprtl-page-title text-center mt-2">Upload Photo</h1>
                                            </div>
                                        </div>   
                                        <div className="col-md-8">
                                            <div className="row">
                                                <div className="col-md-6 form-group">
                                                    <label htmlFor="firstNameInp">First Name</label>
                                                    <input type="text" className="form-control" name="name" defaultValue={formik.values.name} onChange={formik.handleChange} onBlur={formik.handleBlur}/> 
                                                    {formik.touched.name && formik.errors.name ? <div className="text-danger">{formik.errors.name}</div> : null}
                                                </div>
                                                <div className="col-md-6 form-group">
                                                    <label htmlFor="">Email</label>
                                                    <input type="email" className="form-control" name="email" onBlur={checkEmail}  onChange={formik.handleChange} defaultValue={formik.values.email}/>
                                                    {isEmailExist && (<p className="text-danger">Email already Exists!</p>)}
                                                    {formik.touched.email && formik.errors.email ? <div className="text-danger">{formik.errors.email}</div> : null}
                                                </div>
                                                <div className="col-md-6 form-group">
                                                    <label htmlFor="">Phone Number</label>
                                                    <input type="number" className="form-control" name="phone" onBlur={checkPhone}  onChange={formik.handleChange} defaultValue={formik.values.phone}/>
                                                    {isPhoneExist && (<p className="text-danger">Phone number already Exists!</p>)}
                                                    {formik.touched.phone && formik.errors.phone ? <div className="text-danger">{formik.errors.phone}</div> : null}
                                                </div>
                                                <div className="col-md-6 form-group">
                                                    <label htmlFor="genderInp">Gender</label>
                                                    <select id="genderInp" className="form-control" name="gender" defaultValue={formik.values.gender} value={formik.values.gender} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                        <option value="">Please Select</option>
                                                        <option value="Male">Male</option>
                                                        <option value="Female">Female</option>
                                                        <option value="Other">Other</option>
                                                    </select>
                                                    {formik.touched.gender && formik.errors.gender ? <div className="text-danger">{formik.errors.gender}</div> : null}
                                                </div>
                                                <div className="col-md-6 form-group">
                                                    <label htmlFor="">Date of Birth</label>
                                                    <input type="date" className="form-control" name="dateOfBirth"  onChange={formik.handleChange} onBlur={formik.handleBlur} defaultValue={formik.values.dateOfBirth}/>
                                                   
                                                    {formik.touched.dateOfBirth && formik.errors.dateOfBirth ? <div className="text-danger">{formik.errors.dateOfBirth}</div> : null}
                                                </div>
                                                
                                                <div className="col-md-6 form-group">
                                                    <label htmlFor="">Experience</label>
                                                    <input type="number" min="1" className="form-control" name="experience" onChange={formik.handleChange} onBlur={formik.handleBlur} defaultValue={formik.values.experience}/>
                                                    {formik.touched.experience && formik.errors.experience ? <div className="text-danger">{formik.errors.experience}</div> : null}
                                                </div>
                                                <div className="col-md-6 form-group">
                                                    <label htmlFor="">Commision Rate</label>
                                                    <input type="number" className="form-control" name="commisionRate" defaultValue={formik.values.commisionRate} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                                                    {formik.touched.commisionRate && formik.errors.commisionRate ? <div className="text-danger">{formik.errors.commisionRate}</div> : null}
                                                </div>
                                                <div className="col-md-6 form-group">
                                                    <label htmlFor="">Chat Rate (per minute)</label>
                                                    <input type="number" className="form-control" name="chatRate" defaultValue={formik.values.chatRate} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                                                    {formik.touched.chatRate && formik.errors.chatRate ? <div className="text-danger">{formik.errors.chatRate}</div> : null}
                                                </div>
                                                <div className="col-md-6 form-group">
                                                    <label htmlFor="">Call Rate (per minute)</label>
                                                    <input type="number" className="form-control" name="callRate" defaultValue={formik.values.callRate} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                                                    {formik.touched.callRate && formik.errors.callRate ? <div className="text-danger">{formik.errors.callRate}</div> : null}
                                                </div>
                                                <div className="col-md-6 form-group">
                                                    <label htmlFor="">Session Rate (per hour)</label>
                                                    <input type="number" className="form-control" name="sessionRate" defaultValue={formik.values.sessionRate} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                                                    {formik.touched.sessionRate && formik.errors.sessionRate ? <div className="text-danger">{formik.errors.sessionRate}</div> : null}
                                                </div>
                                                <div className="col-md-6 form-group">
                                                    <label htmlFor="">Rating (out of 5)</label>
                                                    <input type="number" min="1" max="5" className="form-control" name="rating" defaultValue={formik.values.rating} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                                                    {formik.touched.rating && formik.errors.rating ? <div className="text-danger">{formik.errors.rating}</div> : null}
                                                </div>
                                                <div className="col-md-12 form-group">
                                                    <label htmlFor="">Categories</label>
                                                    <MultiSelect
                                                        options={categories}
                                                        name="categories"
                                                        value={selected}
                                                        defaultValue={formik.values.categories}
                                                        onChange={(e) => {
                                                            handleChangeMultiSelect(e);
                                                        }}
                                                        labelledBy="Select"
                                                        onBlur={formik.handleBlur}
                                                        // onChange={formik.handleBlur}
                                                        // className="form-control"
                                                    />{formik.touched.categories && formik.errors.categories ? <div className="text-danger">{formik.errors.categories}</div> : null}
                                                </div>                                             
                                                <div className="col-md-12 form-group">
                                                    <label htmlFor="">Description</label>
                                                    <textarea cols="30" rows="3" className="form-control" name="shortDescription" defaultValue={formik.values.shortDescription} onChange={formik.handleChange} onBlur={formik.handleBlur}></textarea>
                                                    {formik.touched.shortDescription && formik.errors.shortDescription ? <div className="text-danger">{formik.errors.shortDescription}</div> : null}
                                                </div>
                                                <div className="col-md-12 form-group">
                                                    <label htmlFor="">Specializations</label>
                                                    <textarea cols="30" rows="3" className="form-control" name="specializations" defaultValue={formik.values.specializations} onChange={formik.handleChange} onBlur={formik.handleBlur}></textarea>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="footer text-right ">
                                    <button type='submit' style={{color:'white'}} className="r-btn r-btn-secondary ml-2" disabled={isEmailExist || isPhoneExist ? true : false}>Save Details</button>
                                </div>
                            </form>  
                        </div>                                                
                        <Education userId={userId}/>
                        <Award userId={userId}/>
                        <ImageVideo userId={userId}/>
                        <SessionsPlans userId={userId}/>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
    <Attachments user={userId ? userId : ''}/>
    </>
  );
}
