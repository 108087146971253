import React, { useEffect, useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { Table } from '../Common/Table';
import { Layout } from '../Layout/Layout';
import axios from 'axios';
import Swal from 'sweetalert2'
import Loader from '../Loader/Loader';

export default function List() {
    const [providers,setProviders] = useState([]);
    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
        getList();
    }, []);
    const getList = async() => {
        setLoading(true)
        await axios.get('/api/users?role='+'Provider')
        .then(function (response) {
        setLoading(false)
        let result = response.data;
            if(result.success){
                setProviders(result.data)
            }
        })
    }
    
    const handleDelete = (userId) => {
        Swal.fire({
            title: 'Are you sure you want to delete?',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`/admin/delete?id=${userId}`).then((res) =>{
                    if(res.data.success){                             
                        Swal.fire('Deleted!', '', 'success')           
                        setProviders(providers.filter((v, i) => v.id !== userId));
                    } 
                }).catch((error) => {                   
                    Swal.fire(error.message, '', 'info')           
                });
            } else if (result.isDenied) {
              Swal.fire('Cancelled!', '', 'info')
            }
        })        
    }

    const handleStatus = (e, id) => {
        var status = 'Active';
        if(e.target.checked === true) {
            status = 'Active'
        }
        else {
            status = 'Inactive'
        }
        axios.patch(`/admin/update/${id}`, {status}).then(res => {                
        })
        .catch(err => {
        })
    }
    const handleIsAvailable = (e, id) => {
        var isAvailable = 1;
        if(e.target.checked === true) {
            isAvailable = '1'
        }
        else {
            isAvailable = '0'
        }
        axios.patch(`/admin/updateAvailability/${id}`, {isAvailable}).then(res => {                
        })
        .catch(err => {
        })
    }
    const columns = [
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
        },
        {
            name: 'Phone',
            selector: row => row.phone,
        },
        {
            name: 'Status',
            cell: row => (
                <div>
                    <input className="tgl tgl-light toggle_user_status" name="status"  id={"chk_"+row.id} type="checkbox" onChange={(e) => handleStatus(e, row.id)} defaultChecked={row.status == 'Active' ? true : false}/>
                    <label title="Active" className="tgl-btn mt-1" htmlFor={"chk_"+row.id} ></label>
                </div>
            ),
        },
        {
            name: 'isAvailable',
            cell: row => (
                <div>
                    <input className="tgl tgl-light toggle_user_isAvailable" name="isAvailable"  id={"isAvailable_"+row.id} type="checkbox" onChange={(e) => handleIsAvailable(e, row.id)} defaultChecked={row.isAvailable == '1' ? true : false}/>
                    <label title="Active" className="tgl-btn mt-1" htmlFor={"isAvailable_"+row.id} ></label>
                </div>
            ),
        },
        {
            name: 'Wallet Details',
            cell: row => (
                <div>
                    <Link to={`/providers/wallet/${row.id}`} className="">
                        {"Show Details"}
                    </Link>
                </div>
            ),
        },
        {
            name: 'Action',
            button: true,
            cell: row => (
                <div>
                    {/* <Link to={`providers/view/${row.id}`} className="icon icon-sm icon-secondary">
                        <i className="fa fa-eye" title="View User"></i>
                    </Link> */}
                    <Link to={`/providers/edit/${row.id}`} className="icon icon-sm icon-secondary ml-1">
                        <i className="fa fa-pencil-alt" title="Edit User"></i>
                    </Link>
                    <a style={{color: '#fff'}} className="icon icon-sm icon-primary ml-1"  onClick={e => handleDelete(row.id)}>
                        <i className="fa fa-times" title="Delete User"></i>
                    </a>
                </div>
            ),
        }
    ];

    return (
        <>
        {loading ? <Loader/> : ''};
            <Layout>  
                <div className="row">
                    <div className="col-6">
                        <h1 className="display-4 ft-dark exprtl-page-title">Providers List</h1>
                    </div>
                    <div className="col-6 text-right">
                        <Link to='/providers/add' className="r-btn r-btn-primary mt-1">
                            <i className="fa fa-plus"></i><span>Add Provider</span>
                        </Link>
                    </div>
                </div>
                <div className="app-white-container">
                    <div className="row">
                        <Table columns={columns} data={providers}/>
                    </div>
                </div>
            </Layout>     
        </>
    )

}