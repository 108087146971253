import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
export default function Header() {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("authUser"));
  const [isToggle, setIsToggle] = useState(false);
  
  // const toogle = useContext(ToggleContext);
  
  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };
  
  const handleToggle = () => {
    var user_profile_div = document.getElementById("user_profile");
    var dropdown_menu = document.getElementById("dropdown_menu");
    if(isToggle === false){
      user_profile_div.classList.add('show');
      dropdown_menu.classList.add('show');
      setIsToggle(true);
    }
    else{
        user_profile_div.classList.remove('show');
        dropdown_menu.classList.remove('show');
        setIsToggle(false);
    }
  }
  return (
    <>
      <div className="app-header header-shadow">
        <div className="app-header__logo">
          <div
            className="logo-src"
            style={{
              backgroundImage: "url('"+process.env.REACT_APP_PUBLIC_URL+"/assets/img/brightlife-logo.png')",
            }}
          ></div>
          <div className="header__pane ml-auto">
            <div>
              {/* <button
                type="button"
                className="hamburger close-sidebar-btn hamburger--elastic is-active"
                data-class="closed-sidebar"
              >
                <span className="hamburger-box">
                  <span className="hamburger-inner"></span>
                </span>
              </button> */}
            </div>
          </div>
        </div>
        <div className="app-header__content">
          <div className="app-header-right">
            <div className="header-btn-lg pr-0">
              <div className="widget-content p-0">
                <div className="widget-content-wrapper">
                  <h4 className="widget-user-heading">Welcome {user.name}</h4>
                  <div className="widget-content-left">
                    <div className="btn-group" onClick={handleToggle} id="user_profile">
                      <a
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        className="p-0 btn"
                      >
                        <img
                          className="rounded-circle width40"
                          src="https://brightlifeapp.s3.us-west-2.amazonaws.com/default-avatar.png"
                          alt=""
                        />
                        <i className="fa fa-angle-down ml-2 opacity-8"></i>
                      </a>
                      <div
                        tabIndex="-1"
                        role="menu"
                        aria-hidden="true"
                        className="dropdown-menu"
                        id="dropdown_menu"
                      >
                        <Link to="/change_password" className="dropdown-item widget-heading">
                        <i className="pe-7s-key mr-3 mt-1 font-size-lg"> </i>{" "}
                          Change Password
                        </Link>

                        <button
                          type="button"
                          tabIndex="0"
                          className="dropdown-item widget-heading"
                          onClick={handleLogout}
                        >
                          {" "}
                          <i className="pe-7s-power mr-3 mt-1 font-size-lg">
                            {" "}
                          </i>{" "}
                          LogOut
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
